import { ReactNode } from 'react';
import { LayoutProps } from '../../types';
import { CookieConsent } from '@prickle/next-ui';

import { useTranslation } from 'next-i18next';
import { ExitPreviewButton, Footer, Navbar } from '../ui';

export const Container = ({ children }: { children: ReactNode }) => (
  <div className="w-full max-w-5xl px-2 mx-auto md:px-4">{children}</div>
);

export function Layout({
  options,
  hideNav,
  menu,
  children,
}: LayoutProps): JSX.Element {
  const { t } = useTranslation('common');
  return (
    <>
      {!hideNav && <Navbar logo={options.text_logo.url} menu={menu} />}
      <main className="w-full h-full overflow-hidden">
        {children}
        <Footer logo={options.stacked_logo.url} menu={menu} />
      </main>
      <ExitPreviewButton />

      <CookieConsent
        isEssentialBanner
        essentialBanner={{
          description: t('cookieHeader'),
          accept: t('cookieAccept'),
          link: t('cookieLink'),
        }}
        privacyPolicyHref="/privacy-policy"
      />
    </>
  );
}
