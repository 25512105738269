import { ResponsiveType, TableModifierType } from '../types';

export const TableSizeProp: {
  [key in ResponsiveType]: string;
} = {
  xs: 'table-xs',
  sm: 'table-sm',
  md: 'table-md',
  lg: 'table-lg',
};

export const TableModifierProp: {
  [key in TableModifierType]: string;
} = {
  'pin-cols': 'table-pin-cols',
  'pin-rows': 'table-pin-rows',
  zebra: 'table-zebra',
};
