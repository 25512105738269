import { AlertColorType } from '../types';

export const AlertColorProp: {
  [key in AlertColorType]: string;
} = {
  info: 'alert-info',
  success: 'alert-success',
  warning: 'alert-warning',
  error: 'alert-error',
};
