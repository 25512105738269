'use client';
import { memo } from 'react';
import { CheckboxInput, CheckboxInputProps } from '../checkbox-input';
import {
  getComponentClasses,
  getComponentColor,
  getComponentSize,
  memoPropsComponent,
} from '../utils';

export function BaseToggle({
  color,
  size,
  className,
  disabled,
  id,
  name,
  label,
  overwriteClass,
  checked,
}: CheckboxInputProps) {
  const toggleClasses = getComponentClasses(
    { className, overwriteClass },
    'toggle',
    getComponentColor('toggle', color),
    getComponentSize('toggle', size),
  );

  return (
    <CheckboxInput
      id={id}
      disabled={disabled}
      label={label}
      name={name}
      overwriteClass
      className={toggleClasses}
      checked={checked}
    />
  );
}

export const Toggle = memo(BaseToggle, memoPropsComponent);
