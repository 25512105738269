import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'next-i18next';

export function Contact(): JSX.Element {
  const { t } = useTranslation('homepage');
  return (
    <section
      id="contact"
      className="flex flex-col justify-center h-screen py-16 m-auto bg-white font-body"
    >
      <div className="max-w-full px-4 py-16 mx-auto text-center sm:py-20 sm:px-6 lg:px-8">
        <h2
          className="font-extrabold leading-none text-white text-7xl sm:text-8xl sm:leading-10"
          data-aos="fade-down"
        >
          <span className="block text-black font-heading">
            {t('headerstart')}
          </span>
        </h2>
        <Link
          href="/contact"
          data-aos="fade-up"
          className="inline-flex items-center justify-center w-full px-10 py-5 mt-8 text-base font-medium text-white transition-all duration-300 ease-in-out bg-black border border-transparent rounded-md md:w-72 sm:mt-20 sm:text-lg hover:bg-primary sm:w-auto"
        >
          {t('headertouch')}
        </Link>
      </div>
    </section>
  );
}
