import { ColorType, ResponsiveType, FieldModifierType } from '../types';

export const TextareaColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'textarea-neutral',
  primary: 'textarea-primary',
  secondary: 'textarea-secondary',
  accent: 'textarea-accent',
  info: 'textarea-info',
  warning: 'textarea-warning',
  success: 'textarea-success',
  error: 'textarea-error',
};

export const TextareaSizeProp: {
  [key in ResponsiveType]: string;
} = {
  xs: 'textarea-xs',
  sm: 'textarea-sm',
  md: 'textarea-md',
  lg: 'textarea-lg',
};

export const TextareaModifierProp: {
  [key in FieldModifierType]: string;
} = {
  ghost: 'textarea-ghost',
  bordered: 'textarea-bordered',
};
