'use client';
import Cookies from 'js-cookie';
import { MouseEvent, useEffect, useState } from 'react';
import { CookieConsentProps } from './types';
import { IconCookie } from '@tabler/icons-react';
import { Link } from '../link';
import { Button } from '../button';
import { Toggle } from '../toggle';
import {
  ANALYTICS_TEXT,
  BANNER_TEXT,
  ESSENTIAL_BANNER_TEXT,
  ESSENTIAL_TEXT,
  MARKETING_TEXT,
} from './data';

const DEFAULT_USER_CONSENT_COOKIE_KEY = 'cookie_consent_is_true';
const DEFAULT_USER_CONSENT_COOKIE_EXPIRE_DATE = 365;

export const CookieConsent = ({
  essential,
  marketing,
  analytics,
  privacyPolicyHref,
  isEssentialBanner,
  essentialBanner,
  cookieKey,
  cookieExpireDate,
  banner,
}: CookieConsentProps) => {
  const USER_CONSENT_COOKIE_KEY = cookieKey || DEFAULT_USER_CONSENT_COOKIE_KEY;
  const USER_CONSENT_COOKIE_EXPIRE_DATE =
    cookieExpireDate || DEFAULT_USER_CONSENT_COOKIE_EXPIRE_DATE;

  const [cookieConsentIsTrue, setCookieConsentIsTrue] = useState(true);

  useEffect(() => {
    const consentIsTrue = Cookies.get(USER_CONSENT_COOKIE_KEY) === 'true';
    setCookieConsentIsTrue(consentIsTrue);
  }, [USER_CONSENT_COOKIE_KEY]);

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!cookieConsentIsTrue) {
      Cookies.set(USER_CONSENT_COOKIE_KEY, 'true', {
        expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setCookieConsentIsTrue(true);
    }
  };

  if (cookieConsentIsTrue) {
    return null;
  }

  if (isEssentialBanner) {
    return (
      <section className="fixed bottom-0 flex-col md:flex-row left-0 right-0 flex gap-4 items-center justify-between p-4 bg-white border-t border-gray-200 dark:bg-[#282828] dark:border-gray-600 z-[100]">
        <div className="flex-1 pr-4">
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {essentialBanner?.description || ESSENTIAL_BANNER_TEXT.description}
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <Button size="sm" color="success" onClick={onClick}>
            {/* TODO: Implement onClick */}
            {essentialBanner?.accept || ESSENTIAL_BANNER_TEXT.accept}
          </Button>
          <Link
            size="sm"
            color="info"
            href={privacyPolicyHref}
            rel="ugc"
            as="next-link"
          >
            {essentialBanner?.link || ESSENTIAL_BANNER_TEXT.link}
          </Link>
        </div>
      </section>
    );
  }

  return (
    <div
      id="v0-container"
      className="!visible transition-opacity duration-150 !opacity-100"
      // style="visibility:hidden;opacity:0"
    >
      <div className="w-full max-w-lg border rounded-lg shadow-sm bg-card text-card-foreground">
        <div className="flex flex-col space-y-1.5 p-6 border-b border-dark-gray-300 pb-4">
          <div className="flex items-center">
            <IconCookie width={24} height={24} className="mr-2" />

            <h3 className="text-2xl font-semibold leading-none tracking-tight">
              {banner?.title || BANNER_TEXT.title}
            </h3>
          </div>
          <p className="text-sm text-muted-foreground">
            {banner?.description || BANNER_TEXT.description}
          </p>
        </div>
        <div className="p-6 pt-4 space-y-4">
          <div className="flex items-start justify-between space-y-2">
            <div>
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="essential"
              >
                {essential?.title || ESSENTIAL_TEXT.title}
              </label>
              <p className="text-sm text-dark-gray-500">
                {essential?.description || ESSENTIAL_TEXT.description}
              </p>
            </div>
            <Toggle
              name="essential"
              checked
              disabled
              aria-label='Toggle Essential Cookies "on" or "off"'
            />
          </div>
          <div className="flex items-start justify-between space-y-2">
            <div>
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="analytics"
              >
                {analytics?.title || ANALYTICS_TEXT.title}
              </label>
              <p className="text-sm text-dark-gray-500">
                {analytics?.description || ANALYTICS_TEXT.description}
              </p>
            </div>
            <Toggle
              name="analytics"
              aria-label='Toggle Analytics Cookies "on" or "off"'
            />
          </div>
          <div className="flex items-start justify-between space-y-2">
            <div>
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="marketing"
              >
                {marketing?.title || MARKETING_TEXT.title}
              </label>
              <p className="text-sm text-dark-gray-500">
                {marketing?.description || MARKETING_TEXT.description}
              </p>
            </div>
            <Toggle
              name="marketing"
              aria-label='Toggle Marketing Cookies "on" or "off"'
            />
          </div>
        </div>
        <div className="mt-4 border-t border-dark-gray-300"></div>
        <div className="flex items-center p-6">
          <Link
            size="sm"
            color="info"
            href={privacyPolicyHref}
            rel="ugc"
            as="next-link"
          >
            {/* TODO: Implement Link Private Policy href */}
            {banner?.link || BANNER_TEXT.link}
          </Link>
          <Button
            size="md"
            color="accent"
            className="inline-flex items-center px-4 py-2 ml-auto"
          >
            {/* TODO: Implement onClick */}
            {banner?.accept || BANNER_TEXT.accept}
          </Button>
        </div>
      </div>
    </div>
  );
};
