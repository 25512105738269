import { ColorType, LinkModifierType } from '../types';

export const LinkColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'link-neutral',
  primary: 'link-primary',
  secondary: 'link-secondary',
  accent: 'link-accent',
  info: 'link-info',
  warning: 'link-warning',
  success: 'link-success',
  error: 'link-error',
};

export const LinkModifierProp: {
  [key in LinkModifierType]: string;
} = {
  hover: 'link-hover',
};
