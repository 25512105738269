import { ColorType } from '../../types';

export const StrokeColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'stroke-neutral',
  primary: 'stroke-primary',
  secondary: 'stroke-secondary',
  accent: 'stroke-accent',
  info: 'stroke-info',
  warning: 'stroke-warning',
  success: 'stroke-success',
  error: 'stroke-error',
};
