import { ColorType, ResponsiveType } from '../types';

export const CheckboxColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'checkbox-neutral',
  primary: 'checkbox-primary',
  secondary: 'checkbox-secondary',
  accent: 'checkbox-accent',
  info: 'checkbox-info',
  warning: 'checkbox-warning',
  success: 'checkbox-success',
  error: 'checkbox-error',
};

export const CheckboxSizeProp: {
  [key in ResponsiveType]: string;
} = {
  xs: 'checkbox-xs',
  sm: 'checkbox-sm',
  md: 'checkbox-md',
  lg: 'checkbox-lg',
};
