import {
  ButtonModifierType,
  ButtonShapeType,
  ButtonSizeType,
  ColorType,
} from '../types';

export const ButtonColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'btn-neutral',
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  accent: 'btn-accent',
  info: 'btn-info',
  warning: 'btn-warning',
  success: 'btn-success',
  error: 'btn-error',
};

export const ButtonSizeProp: {
  [key in ButtonSizeType]: string;
} = {
  xs: 'btn-xs',
  sm: 'btn-sm',
  md: 'btn-md',
  lg: 'btn-lg',
  block: 'btn-block',
  wide: 'btn-wide',
};

export const ButtonModifierProp: {
  [key in ButtonModifierType]: string;
} = {
  active: 'btn-active',
  disabled: 'btn-disabled',
  glass: 'glass',
  noAnimation: 'no-animation',
  outline: 'btn-outline',
  link: 'btn-link',
  ghost: 'btn-ghost',
};

export const ButtonShapeProp: {
  [key in ButtonShapeType]: string;
} = {
  circle: 'btn-circle',
  square: 'btn-square',
};
