import { SidebarNavContainerProps } from './types';

import {
  getComponentClasses,
  getCommonComponentColor,
  BgColorType,
} from '../utils/daisyui';

export const NavContainer = ({
  children,
  color = 'neutral',
}: SidebarNavContainerProps) => {
  const navContainerClasses = getComponentClasses(
    {},
    'min-h-full gap-3 p-4 menu w-60',
    getCommonComponentColor('bg', color),
    getCommonComponentColor('text', color.concat('-content') as BgColorType),
  );

  return (
    <aside className="z-10 drawer-side">
      <label
        htmlFor="drawer"
        aria-label="close sidebar"
        className="drawer-overlay"
      ></label>
      <ul className={navContainerClasses}>{children}</ul>
    </aside>
  );
};
