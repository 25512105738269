import { CollapseProps } from './types/index';

// TODO: Remove this when it comes by default in React
declare module 'react' {
  interface DetailsHTMLAttributes<T> extends React.HTMLAttributes<T> {
    name?: string;
  }
}

const BaseCollapse = ({ title, children, name, open }: CollapseProps) => {
  return (
    <details
      className="bg-base-200 collapse collapse-arrow hover:bg-base-200 focus:bg-base-200 text-base-content"
      name={name}
      open={open}
    >
      <summary className="text-xl font-medium collapse-title">{title}</summary>
      <div className="collapse-content">{children}</div>
    </details>
  );
};

export const Collapse = BaseCollapse;
