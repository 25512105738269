import Image from 'next/image';
import Link from 'next/link';
import { RichText } from 'prismic-reactjs';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { DigitalTransformationProps } from '../../../types';
import { useTranslation } from 'next-i18next';
import { Quoted } from '@prickle/next-ui';
import { IconPencil, IconPrompt } from '@tabler/icons-react';

export function DigitalTransformation({
  title,
  description,
}: DigitalTransformationProps): JSX.Element {
  const [isMounted, setMount] = useState(false);
  const { t } = useTranslation('services');
  useEffect(() => {
    setMount(true);
  }, []);
  return (
    <>
      <section
        id="services"
        className="px-8 py-16 overflow-x-hidden bg-white md:py-0 font-body md:px-0"
      >
        <div className="flex flex-col md:grid md:grid-cols-2">
          <div
            className="flex flex-col pt-16 mx-auto md:px-16 lg:px-32 md:py-8"
            data-aos="fade-right"
          >
            <span className="text-5xl font-extrabold text-left font-heading md:text-4xl lg:text-8xl">
              {RichText.asText(title)}
            </span>
            <p className="mt-8 font-medium prose text-gray-800">
              {RichText.asText(description)}
            </p>

            <div className="relative mt-12 lg:mt-16 font-body lg:items-center">
              <div className="relative">
                {/* TODO: Implment this section on a component and fix the axe-linter error */}
                <dl className="space-y-10">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-primary">
                        <IconPencil size={24} />
                      </div>
                    </div>
                    <div className="ml-4">
                      <dt className="text-lg font-bold leading-6 text-gray-900 ">
                        {t('serviceuidesign')}
                      </dt>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-primary">
                        <IconPrompt size={24} />
                      </div>
                    </div>
                    <div className="ml-4">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        {t('servicewebdevelopment')}
                      </dt>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div className="hidden w-full mr-auto md:flex">
            <Image
              alt="Working tools"
              className="object-cover w-full ml-auto "
              width={972}
              height={1015}
              src="https://images.prismic.io/prickle-website/5f72cc1b-f801-481a-acda-73e8fa6a1f1f_service.jpg?auto=compress,format"
            ></Image>
          </div>
        </div>
        <div className="flex flex-col md:grid md:grid-cols-2">
          <div className="order-2 hidden w-full h-full mr-auto md:flex md:order-2">
            <Image
              alt="Laptop"
              width={972}
              height={1015}
              className="object-cover h-full"
              src="https://images.prismic.io/prickle-website/6a2e777d-c831-4a17-b99f-a36eaf3c92c2_laptop.jpg?auto=compress,format"
            ></Image>
          </div>
          <div
            className="flex flex-col order-1 pt-16 mx-auto md:px-16 lg:px-32 md:order-2 md:py-8"
            data-aos="fade-left"
          >
            <span className="text-5xl font-extrabold text-center font-heading md:text-4xl lg:text-8xl">
              {t('serviceheader')}
            </span>
            <p className="mt-8 font-medium text-gray-800">
              {t('servicedescription1')}{' '}
              <Link href="/contact" className="underline text-primary ">
                {t('servicedescription2')}
              </Link>{' '}
              {t('servicedescription3')}
            </p>
            <div className="relative mt-12 lg:mt-16 font-body lg:items-center">
              <div className="relative">
                {/* TODO: Implment this section on a component and fix the axe-linter error */}
                <dl className="space-y-12">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center flex-1 mb-3 space-y-6 text-sm italic font-medium text-cool-gray-400">
                      {t('serviceexpertise')}
                    </div>
                    <div className="flex flex-row justify-between flex-1">
                      <div className="flex items-center justify-center w-12 h-12">
                        <Image
                          src={'/angular.svg'}
                          alt="Angular"
                          width={36}
                          height={36}
                        />
                      </div>
                      <div className="flex items-center justify-center w-12 h-12">
                        <Image
                          src={'/vue.svg'}
                          alt="Vue"
                          width={36}
                          height={36}
                        />
                      </div>
                      <div className="flex items-center justify-center w-12 h-12">
                        <Image
                          src={'/react.svg'}
                          alt="React"
                          width={36}
                          height={36}
                        />
                      </div>
                      <div className="flex items-center justify-center w-12 h-12">
                        <Image
                          src={'/svelte.svg'}
                          alt="Svelte"
                          width={36}
                          height={36}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-start">
                    <div className="ml-4">
                      <dt className="text-lg font-medium leading-6 text-center text-gray-800">
                        <Quoted>{t('servicequote')}</Quoted>
                      </dt>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center flex-1 mb-3 space-y-6 text-sm italic font-medium text-cool-gray-400">
                      {t('servicepassion')}
                    </div>
                    <div className="flex flex-row justify-center flex-1">
                      <div className="flex items-center justify-center w-12 h-12">
                        <Image
                          src={'/nextjs.svg'}
                          alt="Nextjs"
                          width={36}
                          height={36}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-center">
                    <div className="ml-4">
                      <dt className="text-lg font-medium leading-6 text-center text-gray-800">
                        {t('servicenextjs')}
                      </dt>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isMounted ? (
        <ReactTooltip
          arrowColor="#1C1C1E"
          place="bottom"
          effect="solid"
          className="prickle-tooltip"
        />
      ) : null}
    </>
  );
}
