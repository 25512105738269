import { dataURItoBlob } from './data-uri-to-blob';

export function dataURIWithFilenametoBlob(dataUriWithFilename: string) {
  const dataURI = dataUriWithFilename.substring(
    dataUriWithFilename.indexOf(';') + 1,
  );

  const blob = dataURItoBlob(dataURI);

  return blob;
}
