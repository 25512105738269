import { ColorType, FieldModifierType, ResponsiveType } from '../types';

import {
  RadioColorProp,
  RadioSizeProp,
  TextareaColorProp,
  TextareaSizeProp,
  CheckboxColorProp,
  CheckboxSizeProp,
  TextareaModifierProp,
  TextInputModifierProp,
  TextInputColorProp,
  TextInputSizerProp,
  SelectColorProp,
  SelectSizeProp,
  SelectModifierProp,
  LoadingSizeProp,
  ToggleColorProp,
  ToggleSizeProp,
  LinkColorProp,
  StepColorProp,
} from '../properties';
import {
  FileInputColorProp,
  FileInputModifierProp,
  FileInputSizerProp,
} from '../properties/file-input-properties';

type ComponentType =
  | 'checkbox'
  | 'radio'
  | 'textarea'
  | 'input'
  | 'select'
  | 'file-input'
  | 'loading'
  | 'toggle'
  | 'link'
  | 'step';

export function getComponentColor(
  component: ComponentType,
  color?: ColorType
): string {
  if (!color) return '';

  switch (component) {
    case 'checkbox': {
      return CheckboxColorProp[color];
    }
    case 'radio': {
      return RadioColorProp[color];
    }
    case 'textarea': {
      return TextareaColorProp[color];
    }
    case 'input': {
      return TextInputColorProp[color];
    }
    case 'select': {
      return SelectColorProp[color];
    }
    case 'file-input': {
      return FileInputColorProp[color];
    }
    case 'loading': {
      console.warn('There is no color for loading component');
      return '';
    }
    case 'toggle': {
      return ToggleColorProp[color];
    }
    case 'link': {
      return LinkColorProp[color];
    }
    case 'step': {
      return StepColorProp[color];
    }
    default: {
      return '';
    }
  }
}

export function getComponentSize(
  component: ComponentType,
  size?: ResponsiveType
): string {
  if (!size) return '';

  switch (component) {
    case 'checkbox': {
      return CheckboxSizeProp[size];
    }
    case 'radio': {
      return RadioSizeProp[size];
    }
    case 'textarea': {
      return TextareaSizeProp[size];
    }
    case 'input': {
      return TextInputSizerProp[size];
    }
    case 'select': {
      return SelectSizeProp[size];
    }
    case 'file-input': {
      return FileInputSizerProp[size];
    }
    case 'loading': {
      return LoadingSizeProp[size];
    }
    case 'toggle': {
      return ToggleSizeProp[size];
    }
    case 'link': {
      console.warn('There is no size for link component');
      return '';
    }
    case 'step': {
      console.warn('There is no size for step component');
      return '';
    }
    default: {
      return '';
    }
  }
}

type ComponentFieldType = 'input' | 'textarea' | 'select' | 'file-input';

export function getComponentModifier(
  component: ComponentFieldType,
  modifier?: FieldModifierType,
  isEnabled?: boolean
): string {
  if (!(isEnabled && modifier)) return '';

  switch (component) {
    case 'input': {
      return TextInputModifierProp[modifier];
    }
    case 'textarea': {
      return TextareaModifierProp[modifier];
    }
    case 'select': {
      return SelectModifierProp[modifier];
    }
    case 'file-input': {
      return FileInputModifierProp[modifier];
    }
    default: {
      return '';
    }
  }
}
