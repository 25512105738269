import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { LoadingProps } from './types';
import {
  getCommonComponentColor,
  getComponentClasses,
  getComponentSize,
  getLoadingShape,
} from '../utils';
import { getLoadingIconResponsive } from './get-loading-icon-responsive';

export function Loading({
  textToShow,
  ariaText,
  className,
  color,
  overwriteClass,
  size,
  showIcon,
}: LoadingProps) {
  const loadingClasses = getComponentClasses(
    { className, overwriteClass },
    'loading',
    getCommonComponentColor('text', color),
    getComponentSize('loading', size),
    getLoadingShape('bars')
  );

  const loadingIconClasses = getComponentClasses(
    { className, overwriteClass },
    'animate-[spin_4s_linear_infinite]',
    '-ml-1 mr-3',
    getLoadingIconResponsive(size),
    getCommonComponentColor('text', color)
  );

  if (!showIcon) {
    return <span className={loadingClasses}>{textToShow}</span>;
  }

  return (
    <>
      <Cog8ToothIcon
        className={loadingIconClasses}
        aria-label={ariaText || 'loading...'}
      />

      <span>{textToShow}</span>
    </>
  );
}
