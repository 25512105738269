import { ColorType } from '../../types';

export const AccentColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'accent-neutral',
  primary: 'accent-primary',
  secondary: 'accent-secondary',
  accent: 'accent-accent',
  info: 'accent-info',
  warning: 'accent-warning',
  success: 'accent-success',
  error: 'accent-error',
};
