import { StepsModifierProp } from '../properties';
import { StepsModifierType } from '../types';

export function getStepsModifier(
  modifierKey: StepsModifierType,
  isEnabled?: boolean
) {
  if (!isEnabled) return '';

  return StepsModifierProp[modifierKey];
}
