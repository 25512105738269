import { ToastPositionType } from '../types';

export const ToastPositionProp: {
  [key in ToastPositionType]: string;
} = {
  top: 'toast-top',
  bottom: 'toast-bottom',
  center: 'toast-center',
  middle: 'toast-middle',
  start: 'toast-start',
  end: 'toast-end',
};
