import { ColorType, ResponsiveType, FieldModifierType } from '../types';

export const SelectColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'select-neutral',
  primary: 'select-primary',
  secondary: 'select-secondary',
  accent: 'select-accent',
  info: 'select-info',
  warning: 'select-warning',
  success: 'select-success',
  error: 'select-error',
};

export const SelectSizeProp: {
  [key in ResponsiveType]: string;
} = {
  xs: 'select-xs',
  sm: 'select-sm',
  md: 'select-md',
  lg: 'select-lg',
};

export const SelectModifierProp: {
  [key in FieldModifierType]: string;
} = {
  ghost: 'select-ghost',
  bordered: 'select-bordered',
};
