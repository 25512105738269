import { ColorType } from '../../types';

export const FillColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'fill-neutral',
  primary: 'fill-primary',
  secondary: 'fill-secondary',
  accent: 'fill-accent',
  info: 'fill-info',
  warning: 'fill-warning',
  success: 'fill-success',
  error: 'fill-error',
};
