import { FunctionComponent } from 'react';
import { ButtonProps } from './types';
import {
  getButtonColor,
  getButtonModifier,
  getButtonSize,
  getButtonShape,
  getComponentClasses,
} from '../utils';

export const Button: FunctionComponent<ButtonProps> = ({
  color,
  noAnimation,
  disabled,
  active,
  glass,
  outline,
  shape,
  size,
  className,
  overwriteClass,
  children,
  title,
  type = 'button',
  ghost,
  link,
  ...props
}: ButtonProps) => {
  const btnClasses = getComponentClasses(
    { className, overwriteClass },
    'btn',
    getButtonColor(color),
    getButtonModifier('noAnimation', noAnimation),
    getButtonModifier('disabled', disabled),
    getButtonModifier('active', active),
    getButtonModifier('glass', glass),
    getButtonModifier('outline', outline),
    getButtonShape(shape),
    getButtonSize(size),
    getButtonModifier('ghost', ghost),
    getButtonModifier('link', link),
  );

  return (
    <button
      type={type}
      className={btnClasses}
      title={title}
      disabled={disabled}
      aria-disabled={disabled}
      aria-pressed={active}
      {...props}
    >
      {children}
    </button>
  );
};
