import { AcceptedFilesProp } from './accepted-files-props';
import { AcceptedFileType } from './types';

export function getAcceptedFiles(
  accept?: AcceptedFileType | AcceptedFileType[],
): string {
  if (!accept) {
    return '';
  }

  if (typeof accept === 'string') {
    return AcceptedFilesProp[accept];
  }

  return accept.map((item) => AcceptedFilesProp[item]).join(',');
}
