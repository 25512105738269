import {
  TextInput,
  Button,
  PhoneInput,
  UploadFileField,
} from '@prickle/next-ui';
import { useApplicationForm } from '../../../hooks';

interface ApplicationFormProps {
  tag: string;
  slug: string;
  role: string;
  endpoint: '/api/application' | '/api/assignment';
}

export function ApplicationForm({
  tag,
  slug,
  role,
  endpoint,
}: ApplicationFormProps) {
  const {
    formProperties,
    handleSubmit,
    handleChange,
    errors,
    values,
    handleInputFieldChange,
  } = useApplicationForm({ tag, role, slug, endpoint });

  return (
    <div className="container px-6 mx-auto md:px-0 ">
      <form onSubmit={handleSubmit}>
        <TextInput
          name="name"
          label={{
            className: 'text-white',
            text: formProperties.name.label,
            position: 'top',
            required: formProperties.name.required,
          }}
          placeholder={formProperties.name.label}
          onChange={handleChange}
          value={values.name}
          error={errors.name}
        />
        <TextInput
          name="lastName"
          label={{
            className: 'text-white',
            text: formProperties.lastName.label,
            position: 'top',
            required: formProperties.lastName.required,
          }}
          placeholder={formProperties.lastName.label}
          onChange={handleChange}
          value={values.lastName}
          error={errors.lastName}
        />
        <TextInput
          name="email"
          label={{
            className: 'text-white',
            text: formProperties.email.label,
            position: 'top',
            required: formProperties.email.required,
          }}
          placeholder={formProperties.email.label}
          onChange={handleChange}
          value={values.email}
          error={errors.email}
        />
        <PhoneInput
          name="phoneNumber"
          label={{
            className: 'text-white',
            text: formProperties.phoneNumber.label,
            position: 'top',
            required: formProperties.phoneNumber.required,
          }}
          placeholder={formProperties.phoneNumber.label}
          onChange={handleChange}
          value={values.phoneNumber}
          error={errors.phoneNumber}
        />
        <UploadFileField
          name="letter"
          label={{
            className: 'text-white',
            text: formProperties.letter.label,
            position: 'top',
            required: formProperties.letter.required,
          }}
          onChange={handleInputFieldChange}
          error={errors.letter}
          accept={['docx']}
          value={values.letter}
        />
        <UploadFileField
          name="resume"
          label={{
            className: 'text-white',
            text: formProperties.resume.label,
            position: 'top',
            required: formProperties.resume.required,
          }}
          onChange={handleInputFieldChange}
          error={errors.resume}
          accept={['pdf', 'docx']}
          value={values.resume}
        />

        <Button color="primary" type="submit" size="wide" className="mt-5">
          {formProperties.submit.label}
        </Button>
      </form>
    </div>
  );
}
