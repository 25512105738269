import { useTranslation } from 'next-i18next';

import { displayToast } from '@prickle/next-ui';
import { NewsletterForm } from '../types';
import * as yup from 'yup';
import { useForm } from '@prickle/form/formik';
import { flushSync } from 'react-dom';
import { FormikHelpers } from 'formik';

export function useContactForm() {
  const { t } = useTranslation('contact');
  const submitFormikForm = async (
    { name, email, message, newsletter }: NewsletterForm,
    { resetForm }: FormikHelpers<NewsletterForm>,
  ) => {
    const toast = displayToast({
      message: t('contactsending'),
      type: 'info',
    });

    try {
      await fetch('/api/inbound-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          email,
          message,
          newsletter,
        }),
      });
      flushSync(() => {
        resetForm();
      });
      toast.close();
      displayToast({
        message: t('contactsuccess'),
        type: 'success',
      });
    } catch (_) {
      toast.close();
      displayToast({
        message: t('contacterror'),
        type: 'error',
      });
    }
  };

  const initialValues: NewsletterForm = {
    name: '',
    email: '',
    message: '',
    newsletter: false,
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('contactnamerequired')),
    email: yup.string().email().required(t('contactemailrequired')),
    message: yup.string().required(t('contactmessagerequired')),
    newsletter: yup.boolean(),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    isDisabled,
    errorMessages,
  } = useForm({
    initialValues,
    validationSchema,
    onSubmit: submitFormikForm,
  });

  return {
    submitFormikForm,
    handleChange,
    handleSubmit,
    errors: errorMessages,
    values,
    isDisabled,
    isSubmitting,
  };
}
