import { ColorType, ResponsiveType } from '../types';

export const RadioColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'radio-neutral',
  primary: 'radio-primary',
  secondary: 'radio-secondary',
  accent: 'radio-accent',
  info: 'radio-info',
  warning: 'radio-warning',
  success: 'radio-success',
  error: 'radio-error',
};

export const RadioSizeProp: {
  [key in ResponsiveType]: string;
} = {
  xs: 'radio-xs',
  sm: 'radio-sm',
  md: 'radio-md',
  lg: 'radio-lg',
};
