'use client';
import {
  FormikConfig,
  FormikErrors,
  FormikTouched,
  FormikValues,
  useFormik,
} from 'formik';
import { ErrorMessages } from '../types';

export function useForm<T extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit = () => void 0,
  validateOnMount = true,
  ...props
}: Omit<FormikConfig<T>, 'onSubmit'> & {
  onSubmit?: FormikConfig<T>['onSubmit'];
}) {
  const handleTouched = (key: string) => {
    if (key.indexOf('.') > -1) {
      const [parent, child] = key.split('.');
      formik.setTouched({
        ...formik.touched,
        [parent]: Object.assign({ [child]: true }, formik.touched[parent]),
      });
      return;
    }
    formik.setTouched({ ...formik.touched, [key]: true });
  };
  const checkIfHasTouchedField = (
    key: string,
    validationSchema: FormikConfig<T>['validationSchema'],
  ): boolean => {
    if (validationSchema?.fields[key]?.default() === initialValues[key]) {
      return false;
    }
    return initialValues[key] ? true : false;
  };
  // check each initial values to see if has a value, then it has been touched
  // this is to prevent the form from showing errors on mount
  const initialTouched: FormikTouched<T> = {};
  Object.keys(initialValues).forEach((key: string) => {
    (initialTouched as Record<string, boolean>)[key] = checkIfHasTouchedField(
      key,
      validationSchema,
    );
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount,
    initialTouched,
    ...props,
  });

  const onChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    handleTouched(e.target.name);
    formik.handleChange(e);
  };

  const handleInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleTouched(e.target.name);
    if (e.target.files) {
      formik.setFieldValue(e.target.name, e.target.files[0]); // TODO: handle multiple files
    } else {
      formik.setFieldValue(e.target.name, null);
    }
  };

  const errorMessages: ErrorMessages = {};

  Object.keys(formik.errors).forEach((key: string) => {
    errorMessages[key] = formatError(
      formik.errors,
      formik.touched,
      key,
    ) as string;
  });

  const resetField = (fieldName: string, newValue?: unknown) => {
    const resetValue =
      newValue !== undefined ? newValue : initialValues[fieldName];

    formik.setFieldTouched(fieldName, false, false);
    formik.setFieldValue(fieldName, resetValue, false);
  };

  const hasFieldChanged = (fieldName: string) => {
    return formik.touched[fieldName];
  };

  return {
    errorMessages,
    resetField,
    hasFieldChanged,
    isDisabled: formik.isSubmitting || !formik.isValid,
    ...formik,
    handleChange: onChange,
    handleInputFileChange,
  };
}

function formatError(
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>,
  key: string,
) {
  if (!touched[key]) return '';

  if (Array.isArray(errors[key]) && errors[key]) {
    return (errors[key] as string[]).join(' ');
  }

  if (typeof errors[key] === 'object' && errors[key]) {
    const errorKeys = Object.keys(errors[key] as object);
    const errorObj: Record<string, unknown> = {}; // Add index signature
    errorKeys.forEach((errKey: string) => {
      errorObj[errKey] = formatError(
        errors[key] as FormikErrors<FormikValues>,
        touched[key] as FormikTouched<FormikValues>,
        errKey,
      );
    });
    return errorObj;
  }

  if (typeof errors[key] === 'string' && errors[key])
    return errors[key] as string;

  return '';
}
