/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Find a way to avoid using any
export const memoPropsComponent = (
  prevProps: Readonly<any>,
  nextProps: Readonly<any>,
) => {
  // return !hasObjectChanged(prevProps, nextProps);

  const prevPropsKeys = Object.keys(prevProps);
  const nextPropsKeys = Object.keys(nextProps);

  if (prevPropsKeys.length !== nextPropsKeys.length) {
    return false;
  }

  for (const key of prevPropsKeys) {
    // TODO: check if children has changed
    if (key === 'children') continue;

    if (typeof prevProps[key] !== typeof nextProps[key]) {
      return false;
    }

    if (Array.isArray(prevProps[key])) {
      if (hasArrayChanged(prevProps[key], nextProps[key])) {
        return false;
      }
      continue;
    }

    if (typeof prevProps[key] === 'function') {
      continue;
    }

    if (typeof prevProps[key] === 'object') {
      if (hasObjectChanged(prevProps[key], nextProps[key])) {
        return false;
      }
      continue;
    }

    if (prevProps[key] !== nextProps[key]) {
      return false;
    }
  }

  return true;
};

export const hasObjectChanged = (
  prevProps: Readonly<any>,
  nextProps: Readonly<any>,
) => {
  if (prevProps === null || nextProps === null) {
    return prevProps !== nextProps ? true : false;
  }

  const prevPropsKeys = Object.keys(prevProps);
  const nextPropsKeys = Object.keys(nextProps);

  if (prevPropsKeys.length !== nextPropsKeys.length) {
    return true;
  }

  for (const key of prevPropsKeys) {
    if (key === 'children') {
      // TODO: check if children has changed
      continue;
    }

    if (typeof prevProps[key] !== typeof nextProps[key]) {
      return true;
    }

    if (Array.isArray(prevProps[key])) {
      if (hasArrayChanged(prevProps[key], nextProps[key])) {
        return true;
      }
      continue;
    }

    if (typeof prevProps[key] === 'function') {
      continue;
    }

    if (typeof prevProps[key] === 'object') {
      if (hasObjectChanged(prevProps[key], nextProps[key])) {
        return true;
      }
      continue;
    }
    if (prevProps[key] !== nextProps[key]) {
      return true;
    }
  }

  return false;
};

export const hasArrayChanged = (
  prevProps: Readonly<any>,
  nextProps: Readonly<any>,
) => {
  if (prevProps.length !== nextProps.length) {
    return true;
  }

  for (let i = 0; i < prevProps.length; i++) {
    if (typeof prevProps[i] !== typeof nextProps[i]) {
      return true;
    }

    if (typeof prevProps[i] === 'object') {
      if (hasObjectChanged(prevProps[i], nextProps[i])) {
        return true;
      }
    } else {
      if (prevProps[i] !== nextProps[i]) {
        return true;
      }
    }
  }

  return false;
};
