import { TailwindColor } from '../../types';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function getTailwindTextColor(color: TailwindColor) {
  switch (color) {
    case 'amber': {
      return 'text-amber-700';
    }
    case 'black': {
      return 'text-black';
    }
    case 'blue': {
      return 'text-blue-700';
    }
    case 'current': {
      return 'text-current';
    }
    case 'cyan': {
      return 'text-cyan-700';
    }
    case 'emerald': {
      return 'text-emerald-700';
    }
    case 'fuchsia': {
      return 'text-fuchsia-700';
    }
    case 'gray': {
      return 'text-gray-700';
    }
    case 'green': {
      return 'text-green-700';
    }
    case 'indigo': {
      return 'text-indigo-700';
    }
    case 'inherit': {
      return 'text-inherit';
    }
    case 'lime': {
      return 'text-lime-700';
    }
    case 'neutral': {
      return 'text-neutral-700';
    }
    case 'orange': {
      return 'text-orange-700';
    }
    case 'pink': {
      return 'text-pink-700';
    }
    case 'purple': {
      return 'text-purple-700';
    }
    case 'red': {
      return 'text-red-700';
    }
    case 'rose': {
      return 'text-rose-700';
    }
    case 'sky': {
      return 'text-sky-700';
    }
    case 'slate': {
      return 'text-slate-700';
    }
    case 'stone': {
      return 'text-stone-700';
    }
    case 'teal': {
      return 'text-teal-700';
    }
    case 'transparent': {
      return 'text-transparent-700';
    }
    case 'violet': {
      return 'text-violet-700';
    }
    case 'white': {
      return 'text-white';
    }
    case 'yellow': {
      return 'text-yellow-700';
    }
    case 'zinc': {
      return 'text-zinc-700';
    }
    default: {
      return 'text-gray-700';
    }
  }
}
