import { Text } from '../text';
import {
  getCommonComponentColor,
  getComponentClasses,
  getTextSize,
} from '../utils';
import { ErrorFieldProps } from './types';

export function ErrorField({
  name,
  message,
  className,
  overwriteClass,
  color = 'error',
  size,
}: ErrorFieldProps) {
  const errorClasses = getComponentClasses(
    { className, overwriteClass },
    getCommonComponentColor('text', color),
    getTextSize(size)
  );

  return (
    <Text className={errorClasses} data-testid={`${name}-errors`}>
      {message}
    </Text>
  );
}
