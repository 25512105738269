import Image from 'next/image';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { OptionsDocument } from '../../types.generated';
import { useTranslation } from 'next-i18next';

interface Props {
  data: { title: string; description: Array<RichTextBlock> };
  logo: OptionsDocument['data']['text_logo']['url'];
}
export function About({ data, logo }: Props): JSX.Element {
  const { t } = useTranslation('homepage');
  return (
    <section
      id="about"
      className="h-full px-2 py-32 text-center bg-black md:pt-16 md:px-0"
    >
      <div className="flex flex-col items-center justify-center w-full px-4 mx-auto text-center text-white lg:max-w-screen-xl sm:px-6 ">
        <h2
          className="mb-6 text-6xl font-extrabold text-center lg:text-8xl font-heading"
          data-aos="fade-down"
        >
          {t('headeraboutus1')}{' '}
          <span className="text-primary">{t('headeraboutus2')}</span>
        </h2>
        <div className="max-w-3xl mt-12 text-lg leading-8 " data-aos="fade-up">
          <p>{RichText.asText(data.description)}</p>
        </div>

        <div className="w-72 md:w-96 mb-15 mt-15 bg-dark" data-aos="fade-up">
          <Image
            alt="logo"
            src={logo!}
            className=""
            width={100}
            height={100}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
          ></Image>
        </div>
      </div>
    </section>
  );
}
