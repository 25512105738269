import { ColorType } from '../../types';

export const DecorationColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'decoration-neutral',
  primary: 'decoration-primary',
  secondary: 'decoration-secondary',
  accent: 'decoration-accent',
  info: 'decoration-info',
  warning: 'decoration-warning',
  success: 'decoration-success',
  error: 'decoration-error',
};
