import { BgColorType } from '../../types';

export const BgColorProp: {
  [key in BgColorType]: string;
} = {
  neutral: 'bg-neutral',
  primary: 'bg-primary',
  secondary: 'bg-secondary',
  accent: 'bg-accent',
  info: 'bg-info',
  warning: 'bg-warning',
  success: 'bg-success',
  error: 'bg-error',
  base: 'bg-base',
  'base-100': 'bg-base-100',
  'base-200': 'bg-base-200',
  'base-300': 'bg-base-300',
  'base-content': 'bg-base-content',
  'base-100-content': 'bg-base-100-content',
  'base-200-content': 'bg-base-200-content',
  'base-300-content': 'bg-base-300-content',
  'neutral-content': 'bg-neutral-content',
  'primary-content': 'bg-primary-content',
  'secondary-content': 'bg-secondary-content',
  'accent-content': 'bg-accent-content',
  'info-content': 'bg-info-content',
  'warning-content': 'bg-warning-content',
  'success-content': 'bg-success-content',
  'error-content': 'bg-error-content',
};
