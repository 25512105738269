'use client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormField } from '../form-field';
import {
  getComponentClasses,
  getComponentColor,
  getComponentModifier,
  getComponentSize,
} from '../utils';
import { TextInputProps } from './types';

function BaseTextInput({
  id,
  name,
  className,
  overwriteClass,
  variant,
  responsive,
  bordered,
  ghost,
  placeholder,
  label,
  type = 'text',
  disabled,
  value = '',
  onChange,
  error,
  ...props
}: TextInputProps) {
  const inputClassNames = getComponentClasses(
    { className, overwriteClass },
    'input',
    error
      ? getComponentColor('input', 'error')
      : getComponentColor('input', variant),
    getComponentSize('input', responsive),
    getComponentModifier('input', 'bordered', bordered),
    getComponentModifier('input', 'ghost', ghost),
  );

  const [data, setData] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  useMemo(() => {
    setData(value);
  }, [value]);

  useEffect(() => {
    const form = inputRef.current?.form;
    if (form) {
      const handleReset = () => setData('');
      form.addEventListener('reset', handleReset);

      return () => {
        form.removeEventListener('reset', handleReset);
      };
    }
  }, []);

  return (
    <FormField name={name} label={label} error={error}>
      <input
        ref={inputRef}
        id={id}
        type={type}
        name={name}
        className={inputClassNames}
        placeholder={placeholder}
        disabled={disabled}
        value={data}
        onChange={handleChange}
        {...props}
      ></input>
    </FormField>
  );
}

export const TextInput = BaseTextInput;
