import { ColorType, ResponsiveType } from '../types';

export const ToggleColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'toggle-neutral',
  primary: 'toggle-primary',
  secondary: 'toggle-secondary',
  accent: 'toggle-accent',
  info: 'toggle-info',
  warning: 'toggle-warning',
  success: 'toggle-success',
  error: 'toggle-error',
};

export const ToggleSizeProp: {
  [key in ResponsiveType]: string;
} = {
  xs: 'toggle-xs',
  sm: 'toggle-sm',
  md: 'toggle-md',
  lg: 'toggle-lg',
};
