'use client';
import { useMemo, forwardRef, LegacyRef } from 'react';
import { ErrorField } from '../error-field';
import { Label } from '../label';
import { FormFieldProps } from './types';
import { cn } from '@prickle/ui';

export const FormField = forwardRef(function FormField(
  { name, children, label, error }: FormFieldProps,
  ref: LegacyRef<HTMLElement> | undefined,
) {
  const ComponentWithLabel = useMemo(
    () => (
      <Label ref={ref} containerClassName="cursor-pointer" {...label}>
        {children}
        {error && <ErrorField name={name} message={error} />}
      </Label>
    ),
    [error, name, children, label],
  );

  const ComponentWithoutLabel = useMemo(
    () => (
      <>
        {children}
        {error && <ErrorField name={name} message={error} />}
      </>
    ),
    [error, name, children],
  );

  if (!label) {
    return (
      <div
        ref={ref as LegacyRef<HTMLDivElement>}
        className={cn('form-control')}
      >
        {ComponentWithoutLabel}
      </div>
    );
  }

  return ComponentWithLabel;
});
