import { dataURItoFile } from './data-uri-to-file';

export function dataURIWithFilenametoFile(dataUriWithFilename: string) {
  const filename = dataUriWithFilename.split(';')[0];
  const dataURI = dataUriWithFilename.substring(
    dataUriWithFilename.indexOf(';') + 1
  );

  const file = dataURItoFile(dataURI, filename);

  return file;
}
