import { ColorType } from '../../types';

export const OutlineColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'outline-neutral',
  primary: 'outline-primary',
  secondary: 'outline-secondary',
  accent: 'outline-accent',
  info: 'outline-info',
  warning: 'outline-warning',
  success: 'outline-success',
  error: 'outline-error',
};
