import type { Meta, StoryObj } from '@storybook/react';
import { CookieConsent } from './cookie-consent';

const Story: Meta<typeof CookieConsent> = {
  component: CookieConsent,
  title: 'CookieConsent',
  argTypes: {},
  args: {
    privacyPolicyHref: '/privacy-policy',
  },
};
export default Story;

type Story = StoryObj<typeof CookieConsent>;

export const Default: Story = {
  args: {},
};

export const EssentialBanner: Story = {
  args: {
    isEssentialBanner: true,
    essential: {
      description:
        'We use cookies to enhance your browsing experience. By clicking "Accept", you consent to the use of cookies.',
    },
  },
};

export const MarketingCustomTextBanner: Story = {
  args: {
    marketing: {
      description:
        'Custom message for marketing cookies. These cookies help us show you relevant ads.',
    },
  },
};

export const AnalyticsCustomTextBanner: Story = {
  args: {
    analytics: {
      description:
        'Custom message for analytics cookies. These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site.',
    },
  },
};

export const CustomTextBanner: Story = {
  args: {
    banner: {
      description: 'Custom message for all cookies.',
    },
  },
};

export const CustomText: Story = {
  args: {
    essential: {
      description: 'Custom message for essential cookies.',
    },
    marketing: {
      description: 'Custom message for marketing cookies.',
    },
    analytics: {
      description: 'Custom message for analytics cookies.',
    },
  },
};

export const CustomTextAndBanner: Story = {
  args: {
    banner: {
      description: 'Custom message for all cookies.',
    },
    essential: {
      description: 'Custom message for essential cookies.',
    },
    marketing: {
      description: 'Custom message for marketing cookies.',
    },
    analytics: {
      description: 'Custom message for analytics cookies.',
    },
  },
};
