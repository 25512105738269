import { SidebarProps } from './types/index';
import { Item } from './item';
import { Container } from './container';
import { Content } from './content';
import { NavContainer } from './nav-container';
import { cn } from '@prickle/ui';

const Sidebar = ({ children, right, isOpen, lgOpen }: SidebarProps) => {
  return (
    <div
      className={cn('drawer', {
        'drawer-end': right,
        'lg:drawer-open': lgOpen,
        'drawer-open': isOpen,
      })}
    >
      {children}
    </div>
  );
};

Sidebar.Container = Container;
Sidebar.Content = Content;
Sidebar.NavContainer = NavContainer;
Sidebar.Item = Item;

export { Sidebar };
