import Link from 'next/link';
import { RichText } from 'prismic-reactjs';
import { HeaderProps } from '../../types';
import { useTranslation } from 'next-i18next';

export function Header(props: HeaderProps): JSX.Element {
  const { t } = useTranslation('homepage');
  return (
    <main className="flex items-center justify-center h-screen px-6 mx-auto text-center text-white bg-black lg:px-0 ">
      <div className="text-center lg:text-left lg:-mt-16">
        <h2 className="font-extrabold text-white uppercase text-7xl font-heading header-message lg:text-8xl">
          <span data-aos="fade-up-right" data-aos-delay="300">
            {t('headertitle1')}
          </span>
          <br></br>
          <span
            className="lg:ml-96"
            data-aos-delay="300"
            data-aos="fade-up-left"
          >
            <span className="text-7xl sm:text-8xl lg:px-4 text-primary font-heading lg:text-10xl">
              {t('headertitle2')}
            </span>{' '}
            {t('headertitle3')}
          </span>
        </h2>
        <p
          className="max-w-md mt-6 text-base font-thin text-white sm:text-sm sm:mt-0 md:mt-3 md:text-xl md:max-w-3xl"
          data-aos="fade-up"
        >
          {RichText.asText(props.data.description)}
        </p>

        <div className="w-full text-center mt-36 lg:hidden">
          <Link
            href="/contact"
            data-aos="fade-up"
            className="relative inline-flex items-center px-8 py-4 font-medium leading-5 text-center text-white transition duration-150 ease-in-out border border-transparent rounded-md font-body sm:px-4 sm:py-2 bg-primary md:text-lg md:px-10 md:py-5 text-md hover:bg-primaryLighter focus:outline-none"
          >
            <span>{t('headersubtitle')}</span>
          </Link>
        </div>
      </div>
    </main>
  );
}
