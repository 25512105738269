import { getComponentClasses, getTableModifier } from '../utils';
import { TableProps } from './types';

export function Table({
  className,
  overwriteClass,
  pinCols,
  pinRows,
  zebra,
  children,
}: TableProps) {
  const tableClasses = getComponentClasses(
    { className, overwriteClass },
    'table',
    getTableModifier('pin-cols', pinCols),
    getTableModifier('pin-rows', pinRows),
    getTableModifier('zebra', zebra),
  );

  return <table className={tableClasses}>{children}</table>;
}
