import {
  BgColorProp,
  AccentColorProp,
  BorderColorProp,
  CaretColorProp,
  DecorationColorProp,
  DivideColorProp,
  FillColorProp,
  FromColorProp,
  OutlineColorProp,
  PlaceholderColorProp,
  RingColorProp,
  RingOffsetColorProp,
  ShadowColorProp,
  StrokeColorProp,
  TextColorProp,
  ToColorProp,
  ViaColorProp,
} from '../properties';
import { BgColorType, ColorType } from '../types';

type CommonComponentType =
  | 'bg'
  | 'to'
  | 'via'
  | 'from'
  | 'text'
  | 'ring'
  | 'fill'
  | 'caret'
  | 'stroke'
  | 'border'
  | 'divide'
  | 'accent'
  | 'shadow'
  | 'outline'
  | 'decoration'
  | 'placeholder'
  | 'ring-offset';

export function getCommonComponentColor(
  component: CommonComponentType,
  color?: ColorType | BgColorType,
): string {
  if (!color) return '';

  switch (component) {
    case 'bg': {
      return BgColorProp[color as BgColorType];
    }
    case 'to': {
      return ToColorProp[color as ColorType];
    }
    case 'via': {
      return ViaColorProp[color as ColorType];
    }
    case 'from': {
      return FromColorProp[color as ColorType];
    }
    case 'text': {
      return TextColorProp[color as BgColorType];
    }
    case 'ring': {
      return RingColorProp[color as ColorType];
    }
    case 'fill': {
      return FillColorProp[color as ColorType];
    }
    case 'caret': {
      return CaretColorProp[color as ColorType];
    }
    case 'stroke': {
      return StrokeColorProp[color as ColorType];
    }
    case 'border': {
      return BorderColorProp[color as ColorType];
    }
    case 'divide': {
      return DivideColorProp[color as ColorType];
    }
    case 'accent': {
      return AccentColorProp[color as ColorType];
    }
    case 'shadow': {
      return ShadowColorProp[color as ColorType];
    }
    case 'outline': {
      return OutlineColorProp[color as ColorType];
    }
    case 'decoration': {
      return DecorationColorProp[color as ColorType];
    }
    case 'placeholder': {
      return PlaceholderColorProp[color as ColorType];
    }
    case 'ring-offset': {
      return RingOffsetColorProp[color as ColorType];
    }
    default: {
      return '';
    }
  }
}
