import { NavigationItem } from '../../types';
import Link from 'next/link';

export interface FooterElementProps {
  item: NavigationItem;
}

export function FooterElement({ item }: FooterElementProps) {
  return (
    <div className="pb-6">
      <Link
        href={item.href}
        className="text-sm leading-6 text-white hover:text-gray-400"
      >
        {item.name}
      </Link>
    </div>
  );
}

export default FooterElement;
