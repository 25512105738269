import { ColorType } from '../../types';

export const ShadowColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'shadow-neutral',
  primary: 'shadow-primary',
  secondary: 'shadow-secondary',
  accent: 'shadow-accent',
  info: 'shadow-info',
  warning: 'shadow-warning',
  success: 'shadow-success',
  error: 'shadow-error',
};
