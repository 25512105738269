import { getAlertColor, getComponentClasses } from '../utils';
import { AlertProps } from './types';

export function Alert({
  color,
  children,
  className,
  overwriteClass,
}: AlertProps) {
  const alertClasses = getComponentClasses(
    { className, overwriteClass },
    'alert',
    getAlertColor(color),
  );

  return (
    <div role="alert" className={alertClasses}>
      {children}
    </div>
  );
}
