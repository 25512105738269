export function Services(): JSX.Element {
  return (
    <div className="container flex flex-col justify-center m-auto text-center font-body">
      <h2 className="mb-12 text-4xl font-bold text-white">Services</h2>

      <div className="flex justify-between ">
        <div className="w-full h-64 bg-gray-800 border-t-2 rounded-md shadow-lg border-primaryLighter"></div>
        <div className="w-full h-64 ml-6 bg-gray-800 border-t-2 rounded-md shadow-lg border-primaryLighter"></div>
        <div className="w-full h-64 ml-6 bg-gray-800 border-t-2 rounded-md shadow-lg border-primaryLighter"></div>
      </div>
    </div>
  );
}
