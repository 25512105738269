import { getComponentClasses, getComponentColor } from '../utils';
import { StepProps } from './types';

export function Step({
  color,
  className,
  overwriteClass,
  dataContent,
  text,
}: StepProps) {
  const stepClasses = getComponentClasses(
    { className, overwriteClass },
    'step',
    getComponentColor('step', color),
  );

  return (
    <li className={stepClasses} data-content={dataContent}>
      {text}
    </li>
  );
}
