import { ColorType } from '../../types';

export const ToColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'to-neutral',
  primary: 'to-primary',
  secondary: 'to-secondary',
  accent: 'to-accent',
  info: 'to-info',
  warning: 'to-warning',
  success: 'to-success',
  error: 'to-error',
};
