'use client';
import { TextareaFormFieldProps } from './types';
import {
  getComponentClasses,
  getComponentColor,
  getComponentSize,
  getComponentModifier,
  memoPropsComponent,
} from '../utils';
import { FormField } from '../form-field';
import { memo, useEffect, useMemo, useRef, useState } from 'react';

export function BaseTextareaFormField({
  id,
  name,
  label,
  className,
  overwriteClass,
  color,
  size,
  placeholder,
  bordered,
  ghost,
  disabled,
  rows,
  value = '',
  onChange,
  error,
  ...props
}: TextareaFormFieldProps) {
  const textareaClassNames = getComponentClasses(
    { className, overwriteClass },
    'textarea',
    error
      ? getComponentColor('textarea', 'error')
      : getComponentColor('textarea', color),
    getComponentSize('textarea', size),
    getComponentModifier('textarea', 'bordered', bordered),
    getComponentModifier('textarea', 'ghost', ghost),
  );

  const [data, setData] = useState(value || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  useMemo(() => setData(value), [value]);

  useEffect(() => {
    const form = textareaRef.current?.form;
    if (form) {
      const handleReset = () => setData('');
      form.addEventListener('reset', handleReset);

      return () => {
        form.removeEventListener('reset', handleReset);
      };
    }
  }, []);

  return (
    <FormField name={name} label={label} error={error}>
      <textarea
        ref={textareaRef}
        id={id}
        name={name}
        className={textareaClassNames}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
        value={data}
        onChange={handleChange}
        {...props}
      ></textarea>
    </FormField>
  );
}

export const TextareaFormField = memo(
  BaseTextareaFormField,
  memoPropsComponent,
);
