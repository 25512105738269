import { ButtonModifierProp } from '../properties';
import { ButtonModifierType } from '../types';

export function getButtonModifier(
  modifierKey: ButtonModifierType,
  isEnabled?: boolean
) {
  if (!isEnabled) return '';

  return ButtonModifierProp[modifierKey];
}
