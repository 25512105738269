import { BaseElementProps } from '../../../types';
import { EssentialBanner } from '../cookie-consent.stories';

export interface CookieConsentProps extends BaseElementProps {
  cookieKey?: string;
  cookieExpireDate?: number;
  isEssentialBanner?: boolean;
  essential?: CookieSection;
  analytics?: CookieSection;
  marketing?: CookieSection;
  privacyPolicyHref: string;
  essentialBanner?: EssentialBanner;
  banner?: CookieBanner;
}

interface CookieSection extends BaseElementProps {
  title?: string;
  description?: string;
}

interface EssentialBanner {
  description: string;
  accept: string;
  link: string;
}

interface CookieBanner {
  title?: string;
  description?: string;
  accept?: string;
  link?: string;
}
