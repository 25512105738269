import { Step } from '../step';
import { getComponentClasses, getStepsModifier } from '../utils';
import { StepsProps } from './types';

export function Steps({
  horizontal,
  vertical,
  className,
  overwriteClass,
  color,
  steps,
}: StepsProps) {
  const stepsClasses = getComponentClasses(
    { overwriteClass, className },
    'steps',
    getStepsModifier('vertical', vertical),
    getStepsModifier('horizontal', horizontal)
  );

  return (
    <ul className={stepsClasses}>
      {steps.map((stepItem, index) => (
        <Step key={index} {...stepItem} color={stepItem.color || color} />
      ))}
    </ul>
  );
}
