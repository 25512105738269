'use client';
import { usePathname } from 'next/navigation';
import { Link } from '../link';
import { SidebarItemProps } from './types/index';
import { cn } from '@prickle/ui';

export const Item = ({
  pathname,
  icon,
  label,
  className,
}: SidebarItemProps) => {
  const pathName = usePathname();

  const isActive = pathName === pathname;

  return (
    <li
      className={cn(
        'bg-neutral hover:bg-base-100/30 hover:text-base-content transition-all rounded-lg',
        {
          'bg-base-100/70 text-base-content': isActive,
        },
        className,
      )}
    >
      <Link
        href={pathname}
        as="next-link"
        hover
        onClick={() => {
          document.getElementById('drawer')?.click();
        }}
      >
        {icon} {label}
      </Link>
    </li>
  );
};
