'use client';
import { memo, useState } from 'react';
import { CheckboxInputProps } from './types';
import {
  getComponentColor,
  getComponentSize,
  getComponentClasses,
  memoPropsComponent,
} from '../utils';

import { FormField } from '../form-field';

export function BaseCheckboxInput({
  id,
  name,
  label,
  disabled = false,
  checked = false,
  color,
  size,
  onChange,
  className,
  overwriteClass,
  error,
  value = name,
  ...props
}: CheckboxInputProps) {
  const [isChecked, setChecked] = useState<boolean>(checked);

  const checkboxClasses = getComponentClasses(
    { className, overwriteClass },
    'checkbox',
    getComponentColor('checkbox', color),
    getComponentSize('checkbox', size),
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(Boolean(!isChecked));
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormField name={name} label={label} error={error}>
      <input
        id={id}
        aria-describedby={value}
        aria-checked={isChecked}
        name={name}
        type="checkbox"
        disabled={disabled}
        className={checkboxClasses}
        onChange={handleChange}
        value={value}
        checked={isChecked}
        {...props}
      />
    </FormField>
  );
}

export const CheckboxInput = memo(BaseCheckboxInput, memoPropsComponent);
