import { ColorType, FieldModifierType, ResponsiveType } from '../types';

export const TextInputColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'input-neutral',
  primary: 'input-primary',
  secondary: 'input-secondary',
  accent: 'input-accent',
  info: 'input-info',
  warning: 'input-warning',
  success: 'input-success',
  error: 'input-error',
};

export const TextInputSizerProp: {
  [key in ResponsiveType]: string;
} = {
  xs: 'input-xs',
  sm: 'input-sm',
  md: 'input-md',
  lg: 'input-lg',
};

export const TextInputModifierProp: {
  [key in FieldModifierType]: string;
} = {
  ghost: 'input-ghost',
  bordered: 'input-bordered',
};
