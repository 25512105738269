import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { RichText } from 'prismic-reactjs';
import { ConsultancyProps } from '../../../types';
import { useTranslation } from 'next-i18next';
import { IconPencil, IconPrompt } from '@tabler/icons-react';

export function Consultancy({
  title,
  description,
  secondDescription,
  companies,
}: ConsultancyProps): JSX.Element {
  const { t } = useTranslation('services');
  return (
    <section
      id="services"
      className="px-8 py-16 overflow-x-hidden bg-white md:py-0 font-body md:px-0"
    >
      <div className="flex flex-col md:grid md:grid-cols-2">
        <div
          className="flex flex-col order-1 pt-16 mx-auto md:px-16 lg:px-32 md:order-2 md:py-8"
          data-aos="fade-right"
        >
          <span className="text-5xl font-extrabold text-center font-heading md:text-4xl lg:text-8xl">
            {RichText.asText(title)}
          </span>
          <p className="mt-8 font-medium text-gray-800">
            {RichText.asText(description)}
          </p>
          <div className="relative mt-12 lg:mt-16 font-body lg:items-center">
            <div className="relative">
              {/* TODO: Implment this section on a component and fix the axe-linter error */}
              <dl className="space-y-10">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-primary">
                      <IconPencil size={24} />
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg font-bold leading-6 text-gray-900 ">
                      {t('servicedelivery')}
                    </dt>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-primary">
                      <IconPrompt size={24} />
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg font-bold leading-6 text-gray-900">
                      {t('serviceconsultancy')}
                    </dt>
                  </div>
                </div>
              </dl>
            </div>
          </div>
          <p className="mt-8 font-medium text-gray-800">
            {RichText.asText(secondDescription)} {t('servicecontact1')}{' '}
            <Link href="/contact" className="underline text-primary">
              {t('servicecontact2')}
            </Link>{' '}
            {t('servicecontact3')}
          </p>
          <div className="relative mt-12 lg:mt-16 font-body lg:items-center">
            <div className="relative">
              {/* TODO: Implment this section on a component and fix the axe-linter error */}
              <dl className="space-y-12">
                <div className="flex flex-col">
                  <div className="flex flex-row justify-center flex-1 mb-3 space-y-6 text-sm italic font-medium text-cool-gray-400">
                    {t('serviceourclients')}
                  </div>
                  <div className="flex flex-row justify-between flex-1">
                    {companies.map((company, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center w-12 h-12"
                      >
                        <Image
                          className="object-cover w-48 h-48 transition-all duration-300 hover:filter-none"
                          src={company.logo.url}
                          alt="employee profile"
                          width={500}
                          height={500}
                          sizes="100vw"
                          style={{ width: '100%', height: 'auto' }}
                        ></Image>
                      </div>
                    ))}
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="order-2 hidden w-full h-full mr-auto md:flex md:order-2">
          <Image
            alt="Laptop"
            width={972}
            height={1015}
            className="object-cover h-full"
            src="https://images.prismic.io/prickle-website/43fcfaea-7858-428a-ba6d-1cacc88668af_services-consultancy.jpg?auto=compress,format"
          ></Image>
        </div>
      </div>
    </section>
  );
}
