import { ColorType } from '../../types';

export const DivideColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'divide-neutral',
  primary: 'divide-primary',
  secondary: 'divide-secondary',
  accent: 'divide-accent',
  info: 'divide-info',
  warning: 'divide-warning',
  success: 'divide-success',
  error: 'divide-error',
};
