import axios from 'axios';
export async function dataURItoBlob(dataURI: string) {
  // Split the data URI into parts
  const parts = dataURI.split(',');

  if (parts.length !== 2) throw new Error('invalid data URI');
  if (!parts[0]) throw new Error('invalid data URI');

  // FIXME: It should work with node 18 and jest 28
  // SEE: https://jestjs.io/blog/2022/04/25/jest-28#all-nodejs-globals
  // const res = await fetch(dataURI);
  // const blob = await res.blob();

  const res = await axios.get(dataURI, { responseType: 'blob' });
  const blob = res.data;

  return blob;
}
