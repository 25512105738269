'use client';
import { CountryRegionData } from 'react-country-region-selector';
import { FormField } from '../form-field';
import { getCountryFlag } from '../utils';
import { CountrySelectionProps } from './types';
import { Select } from '../select';
import { CountryCode } from '../../types';

function BaseCountrySelection({
  name,
  label,
  value = '',
  onChange,
  ...props
}: CountrySelectionProps) {
  return (
    <FormField name={name} label={label}>
      <Select name={name} onChange={onChange} {...props} defaultValue={value}>
        {CountryRegionData.map((country) => {
          const optionValue = country[1];
          const label = `${getCountryFlag(
            optionValue.toLowerCase() as CountryCode,
          )} ${country[0]}`;

          return (
            <Select.Option
              key={optionValue}
              value={optionValue}
              label={label}
            />
          );
        })}
      </Select>
    </FormField>
  );
}

export const CountrySelection = BaseCountrySelection;
