'use client';
import { useState, useMemo, useCallback } from 'react';
import { RadioInputProps } from './types';
import {
  getComponentColor,
  getComponentSize,
  getComponentClasses,
} from '../utils';
import { FormField } from '../form-field';

export function BaseRadioInput({
  id,
  name,
  label,
  disabled = false,
  checked = false,
  color,
  size,
  onChange,
  className,
  overwriteClass,
  error,
  value = name,
  ...props
}: RadioInputProps) {
  const [isChecked, setChecked] = useState<boolean>(checked);

  const checkboxClasses = getComponentClasses(
    { className, overwriteClass },
    'radio',
    getComponentColor('radio', color),
    getComponentSize('radio', size),
  );

  useMemo(() => {
    setChecked(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(Boolean(!isChecked));
      if (onChange) {
        onChange(e);
      }
    },
    [isChecked, onChange],
  );

  return (
    <FormField name={name} label={label} error={error}>
      <input
        id={id}
        aria-describedby={value}
        aria-checked={isChecked}
        name={name}
        type="radio"
        disabled={disabled}
        className={checkboxClasses}
        onChange={handleChange}
        value={value}
        checked={isChecked}
        {...props}
      />
    </FormField>
  );
}

export const RadioInput = BaseRadioInput;
