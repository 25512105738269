import { ColorType } from '../../types';

export const FromColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'from-neutral',
  primary: 'from-primary',
  secondary: 'from-secondary',
  accent: 'from-accent',
  info: 'from-info',
  warning: 'from-warning',
  success: 'from-success',
  error: 'from-error',
};
