'use client';
import { RadioInput } from '../radio-input';
import { RadioGroupInputProps } from './types';
import { getComponentClasses } from '../utils';
import { FormField } from '../form-field';
import { ChangeEvent, useMemo, useState, useCallback } from 'react';
import { Label } from '../label';

function BaseRadioGroupInput({
  direction = 'row',
  items,
  color,
  overwriteClass,
  className,
  id,
  disabled,
  size,
  name,
  label,
  value,
  error,
  onChange,
  ...props
}: RadioGroupInputProps) {
  const [data, setData] = useState(value);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setData(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  useMemo(() => {
    setData(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const radioGroupInputClases = getComponentClasses(
    { overwriteClass, className },
    'grid',
    direction === 'column'
      ? 'grid-flow-col auto-cols-max'
      : 'grid-flow-row auto-rows-max',
    'gap-1',
  );

  return (
    <div className={radioGroupInputClases} id={id} {...props}>
      <FormField name="" error={error}>
        {label && <Label {...label} />}
        {items.map((item, index) => {
          const { label, ...itemProps } = item;

          const checked = itemProps.value === data;

          return (
            <RadioInput
              key={index}
              name={name}
              label={label}
              checked={checked}
              disabled={item.disabled ? item.disabled : disabled}
              color={item.color ? item.color : color}
              size={item.size ? item.size : size}
              {...itemProps}
              onChange={handleChange}
            />
          );
        })}
      </FormField>
    </div>
  );
}

export const RadioGroupInput = BaseRadioGroupInput;
