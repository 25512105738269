import { TbMenu2 } from 'react-icons/tb';
import { SidebarContainerProps } from './types';

export const Container = ({ children, withoutMenu }: SidebarContainerProps) => {
  return (
    <>
      <input id="drawer" type="checkbox" className="drawer-toggle" />
      {withoutMenu ? null : (
        <label
          htmlFor="drawer"
          className="absolute top-4 left-4 lg:hidden"
          aria-label="Menu"
        >
          <TbMenu2 size={32} />
        </label>
      )}
      <div className="drawer-content">{children}</div>
    </>
  );
};
