import React from 'react';
import Image from 'next/image';
import { RichText } from 'prismic-reactjs';
import { StaffAugmentationProps } from '../../../types';
import { Quoted } from '@prickle/next-ui';

export function StaffAugmentation({
  title,
  description,
  secondDescription,
}: StaffAugmentationProps): JSX.Element {
  return (
    <section
      id="services"
      className="px-8 py-16 overflow-x-hidden bg-white md:py-0 font-body md:px-0"
    >
      <div className="flex flex-col md:grid md:grid-cols-2">
        <div className="order-2 hidden w-full h-full mr-auto md:flex md:order-2">
          <Image
            alt="Laptop"
            width={972}
            height={1015}
            className="object-cover h-full"
            src="https://images.prismic.io/prickle-website/23e3c0cb-c9e4-4e87-8022-24f37081e517_staf_augmentation.jpg?auto=compress,format"
          ></Image>
        </div>
        <div
          className="flex flex-col order-1 pt-16 mx-auto md:px-16 lg:px-32 md:order-2 md:py-8"
          data-aos="fade-left"
        >
          <span className="text-5xl font-extrabold text-center font-heading md:text-4xl lg:text-8xl">
            {RichText.asText(title)}
          </span>
          <p className="mt-8 font-medium text-gray-800">
            {RichText.asText(description)}
          </p>
          <div className="relative mt-12 lg:mt-16 font-body lg:items-center">
            <div className="relative">
              <dl className="space-y-12">
                <div className="flex flex-col items-start">
                  <div className="ml-4">
                    <dt className="text-lg font-medium leading-6 text-center text-gray-800">
                      <Quoted>{RichText.asText(secondDescription)}</Quoted>
                    </dt>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
