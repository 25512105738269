import { FooterProps } from './types';
import { getComponentClasses } from '../utils/';
import { getCommonComponentColor } from '../utils/daisyui/common-components/get-common-component-color';

export function Footer({
  className,
  overwriteClass,
  color = 'base-300',
  textColor = 'base-content',
  center = false,
  children,
}: FooterProps) {
  const footerClass = getComponentClasses(
    { className, overwriteClass },
    'footer',
    center ? 'footer-center' : '',
    getCommonComponentColor('bg', color),
    getCommonComponentColor('text', textColor),
  );

  return <footer className={footerClass}>{children}</footer>;
}
