import { RichText } from 'prismic-reactjs';
import { TestimonialProps } from '../../types';
import Image from 'next/image';

export function Testimonial({ data }: TestimonialProps): JSX.Element {
  return (
    <section className="py-12 bg-white md:py-20 lg:py-48">
      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative" data-aos="fade-up">
          <Image
            className="w-64 mx-auto"
            src={data[0].data.company_logo.url}
            width={0}
            height={0}
            alt="Workcation"
          ></Image>
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-xl font-medium leading-9 text-center text-gray-800">
              <p>{RichText.asText(data[0].data.content)}</p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <Image
                    className="object-cover w-10 h-10 mx-auto rounded-full"
                    src={data[0].data.author_photo.url}
                    width={0}
                    height={0}
                    alt=""
                  ></Image>
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    {RichText.asText(data[0].data.author)}
                  </div>

                  <svg
                    className="hidden w-5 h-5 mx-1 text-primary md:block"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    {RichText.asText(data[0].data.role)},{' '}
                    {RichText.asText(data[0].data.company_name)}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
}
