import { LinkModifierProp } from '../properties/link-properties';
import { LinkModifierType } from '../types';

export function getLinkModifier(
  modifierKey: LinkModifierType,
  isEnabled?: boolean
) {
  if (!isEnabled) return '';

  return LinkModifierProp[modifierKey];
}
