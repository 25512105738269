import { forwardRef, LegacyRef } from 'react';
import { Text } from '../text';
import { getCommonComponentColor, getComponentClasses } from '../utils';
import { LabelProps } from './types';

export const Label = forwardRef(function Label(
  {
    text,
    className,
    color,
    children,
    overwriteClass,
    containerClassName,
    position = 'left',
    required,
    htmlFor,
    ...labelProps
  }: LabelProps,
  ref: LegacyRef<HTMLElement> | undefined,
) {
  const labelClasses = getComponentClasses(
    { overwriteClass, className },
    'label-text',
    getCommonComponentColor('text', color),
    position === 'right' ? 'ml-2' : 'mr-2',
  );

  const labelContainerClasses = getComponentClasses(
    { overwriteClass, className: containerClassName },
    'label',
    position === 'right' ? 'flex-row-reverse' : '',
    'gap-2',
  );

  const Required = () => {
    if (!required) return null;

    if (typeof required === 'boolean') {
      return (
        <>
          {' '}
          <Text text="*" color="error" size="md" />
        </>
      );
    }

    return (
      <>
        {' '}
        <Text text="*" size="md" {...required} />
      </>
    );
  };

  const Span = () => (
    <span className={labelClasses}>
      {text}
      <Required />
    </span>
  );

  if (position === 'bottom') {
    return (
      <label
        ref={ref as LegacyRef<HTMLLabelElement>}
        htmlFor={htmlFor}
        className="form-control"
      >
        {children}
        <div className={labelContainerClasses} {...labelProps}>
          <Span />
        </div>
      </label>
    );
  }

  if (position === 'top') {
    return (
      <label
        ref={ref as LegacyRef<HTMLLabelElement>}
        className="form-control"
        htmlFor={htmlFor}
      >
        <div className={labelContainerClasses} {...labelProps}>
          <Span />
        </div>
        {children}
      </label>
    );
  }

  return (
    <div
      ref={ref as LegacyRef<HTMLDivElement>}
      className="flex-row gap-5 form-control"
    >
      <label
        htmlFor={htmlFor}
        className={labelContainerClasses}
        {...labelProps}
      >
        <Span />
        {children}
      </label>
    </div>
  );
});
