'use client';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { classNames } from '../utils';

export function LanguageSelection(): JSX.Element {
  const { locale, locales, push, pathname, query, asPath } = useRouter();
  const languageNames = new Intl.DisplayNames(locales, { type: 'language' });
  const changeLocale = (locale: string) => {
    push({ pathname, query }, asPath, { locale });
  };
  return (
    <Menu
      as="div"
      data-testid="language-selector"
      className="relative inline-block text-left"
    >
      <div>
        <Menu.Button className="flex items-center text-white rounded-full hover:text-gray-600 focus:outline-none">
          <span className="sr-only">Open options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {locales &&
              locales.map((lang) => (
                <Menu.Item
                  className="cursor-pointer"
                  as={'div'}
                  key={lang}
                  onClick={() => changeLocale(lang)}
                >
                  <span
                    className={classNames(
                      locale === lang
                        ? 'text-primary bg-gray-100'
                        : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    <ReactCountryFlag
                      className="mr-1"
                      countryCode={lang.split('-')[1]}
                      svg
                      title={`${lang.split('-')[1]}-flag`}
                    />
                    {languageNames.of(lang)}
                  </span>
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
