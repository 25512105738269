import { ColorType } from '../../types';

export const ViaColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'via-neutral',
  primary: 'via-primary',
  secondary: 'via-secondary',
  accent: 'via-accent',
  info: 'via-info',
  warning: 'via-warning',
  success: 'via-success',
  error: 'via-error',
};
