import {
  getCommonComponentColor,
  getComponentClasses,
  getTextSize,
} from '../utils';
import { TextProps } from './types';

export function Text({
  text,
  color,
  overwriteClass,
  className,
  size,
  children,
  ...props
}: TextProps) {
  const textClasses = getComponentClasses(
    { overwriteClass, className },
    getCommonComponentColor('text', color),
    getTextSize(size)
  );

  return (
    <span className={textClasses} {...props}>
      {text || children}
    </span>
  );
}
