import { ColorType } from '../../types';

export const PlaceholderColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'placeholder-neutral',
  primary: 'placeholder-primary',
  secondary: 'placeholder-secondary',
  accent: 'placeholder-accent',
  info: 'placeholder-info',
  warning: 'placeholder-warning',
  success: 'placeholder-success',
  error: 'placeholder-error',
};
