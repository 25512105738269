import { ColorType } from '../../types';

export const RingColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'ring-neutral',
  primary: 'ring-primary',
  secondary: 'ring-secondary',
  accent: 'ring-accent',
  info: 'ring-info',
  warning: 'ring-warning',
  success: 'ring-success',
  error: 'ring-error',
};
