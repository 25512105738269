'use client';
import { FormField } from '../form-field';
import {
  getComponentClasses,
  getComponentColor,
  getComponentModifier,
  getComponentSize,
} from '../utils';
import { SelectProps } from './types';
import { Option } from './option';

function Select({
  name,
  label,
  className,
  color,
  disabled,
  size,
  overwriteClass,
  id,
  options,
  bordered,
  ghost,
  error,
  children,
  ...props
}: SelectProps) {
  const selectClasses = getComponentClasses(
    { className, overwriteClass },
    'select',
    error
      ? getComponentColor('select', 'error')
      : getComponentColor('select', color),
    getComponentSize('select', size),
    getComponentModifier('select', 'bordered', bordered),
    getComponentModifier('select', 'ghost', ghost),
  );

  return (
    <FormField name={name || ''} label={label} error={error}>
      <select
        id={id}
        name={name}
        disabled={disabled}
        className={selectClasses}
        {...props}
      >
        {options &&
          options.map(({ value, label, ...optionProps }) => (
            <option key={`${value}`} {...optionProps} value={value}>
              {label}
            </option>
          ))}

        {children}
      </select>
    </FormField>
  );
}

Select.Option = Option;

export { Select };
