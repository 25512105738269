'use client';
import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { CheckboxInput } from '../checkbox-input';
import {
  getComponentClasses,
  hasArrayChanged,
  memoPropsComponent,
} from '../utils';
import { CheckboxGroupInputProps } from './types';
import { FormField } from '../form-field';
import { Label } from '../label';

function BaseCheckboxGroupInput({
  direction = 'row',
  items,
  color,
  overwriteClass,
  className,
  id,
  disabled,
  size,
  name,
  label,
  value = [],
  error,
  onChange,
  ...props
}: CheckboxGroupInputProps) {
  const [data, setData] = useState(value);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      data?.includes(e.target.value)
        ? setData(data.filter((d) => d !== e.target.value))
        : setData([...data, e.target.value]);

      if (onChange) {
        onChange(e);
      }
    },
    [data, onChange],
  );

  const checkboxGroupInputClases = getComponentClasses(
    { overwriteClass, className },
    'grid',
    direction === 'column'
      ? 'grid-flow-col auto-cols-max'
      : 'grid-flow-row auto-rows-max',
    'gap-1',
  );

  useMemo(() => {
    if (hasArrayChanged(value, data)) setData(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={checkboxGroupInputClases} id={id} {...props}>
      <FormField name="" error={error}>
        {label && <Label {...label} />}
        {items.map((item) => {
          const { id, label, value, checked, ...itemProps } = item;
          const isChecked = data?.includes(value) || checked;

          return (
            <CheckboxInput
              key={value}
              id={id}
              label={label}
              checked={isChecked}
              disabled={item.disabled ? item.disabled : disabled}
              color={item.color ? item.color : color}
              size={item.size ? item.size : size}
              {...itemProps}
              name={name}
              value={value}
              onChange={handleChange}
            />
          );
        })}
      </FormField>
    </div>
  );
}

export const CheckboxGroupInput = memo(
  BaseCheckboxGroupInput,
  memoPropsComponent,
);
