import { cn } from '@prickle/ui';
import { Link } from '../link';

import { NavigationElementProps } from './types';

export function NavigationElement({ item }: NavigationElementProps) {
  return (
    <li>
      <Link
        href={item.href}
        as="next-link"
        hover
        aria-current={item.current ? 'page' : undefined}
        className={cn(item.current ? 'active' : '')}
      >
        {item.name}
      </Link>
    </li>
  );
}
