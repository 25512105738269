import Image from 'next/image';
import Link from 'next/link';
import { RichText } from 'prismic-reactjs';
import { TeamProps } from '../../types';

export function Team({ data, members }: TeamProps): JSX.Element {
  return (
    <section id="team" className="w-full px-8 py-24 bg-black lg:py-24">
      <div className="max-w-screen-xl px-4 py-12 mx-auto text-center lg:py-24">
        <div className="space-y-32">
          <div className="flex flex-col items-center space-y-5 sm:mx-auto sm:space-y-4 ">
            <h2
              className="text-5xl text-center text-white lg:text-8xl font-heading"
              data-aos="fade-down"
            >
              {typeof data.title !== 'string' && RichText.asText(data.title)}

              {typeof data.title === 'string' && data.title}
            </h2>
            {/* <p
              className="mt-16 text-xl leading-7 text-white"
              data-aos="fade-down"
            >
              {typeof data.description !== 'string' &&
                RichText.asText(data.title)}
              {typeof data.description === 'string' && data.description}
            </p> */}
          </div>
          <ul className="grid gap-16 justify-items-center place-content-center sm:grid-cols-6 md:grid-cols-3 md:grid-rows-1">
            {members.map((member, index) => (
              <Link
                key={member.uid}
                href={`/team-member/${member.uid}`}
                passHref
              >
                <li
                  key={member.uid}
                  className={`cursor-pointer col-start-1 col-span-4 md:col-span-0 md:col-start-${
                    index + 1
                  } md:col-end-${index + 2}`}
                  data-aos="fade-down"
                >
                  <div className="inline-block object-cover w-48 h-48 space-y-6 rounded-full">
                    <Image
                      className="object-cover w-48 h-48 transition-all duration-300 rounded-full filter-grayscale hover:filter-none"
                      src={member.data.image.url}
                      alt="employee profile"
                      width={500}
                      height={500}
                      sizes="100vw"
                      style={{ width: '100%', height: 'auto' }}
                    ></Image>
                    <div className="mx-auto space-y-2 text-center">
                      <div className="space-y-1 text-lg font-medium leading-6 tracking-wide ">
                        <div className="mb-2 text-3xl text-white font-heading-alt">
                          {RichText.asText(member.data.name)}
                        </div>
                        <div className="text-primary">
                          {RichText.asText(member.data.role)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
