import { Link } from '../link/link';
/**
 * v0 by Vercel.
 * @see https://v0.dev/t/Tyx7eJAq187
 */

export function UnderConstructionBanner() {
  return (
    <section className="flex flex-col items-center justify-center h-full gap-4 bg-base-100 text-base-content max-h-min">
      <ConstructionIcon className="w-24 h-24 text-warning" />
      <h1 className="text-3xl font-bold">Under Construction</h1>
      <p className="max-w-md text-lg text-center">
        This page is currently under construction. We're working hard to get it
        ready for you. Stay tuned!
      </p>
      <Link color="info" href="/">
        Go back to the main page
      </Link>
    </section>
  );
}

function ConstructionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="2" y="6" width="20" height="8" rx="1" />
      <path d="M17 14v7" />
      <path d="M7 14v7" />
      <path d="M17 3v3" />
      <path d="M7 3v3" />
      <path d="M10 14 2.3 6.3" />
      <path d="m14 6 7.7 7.7" />
      <path d="m8 6 8 8" />
    </svg>
  );
}
