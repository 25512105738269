'use client';

import { useFormContext } from 'react-hook-form';
import { ErrorField } from '../error-field/error-field';
import { InputFieldProps } from './types';
import { classNames, getTailwindTextColor } from '../utils/tailwind';
import { useMemo } from 'react';

export const InputField = ({
  name,
  colSpan = 'col-span-1',
  label,
  required,
  placeholder,
  labelColor = 'gray',
  labelClassName = '',
  inputColor = 'gray',
  inputClassName = '',
}: InputFieldProps) => {
  const { getFieldState, register } = useFormContext();

  const { error } = getFieldState(name);

  const labelClasses = useMemo(() => {
    return classNames(
      'block text-sm font-medium',
      getTailwindTextColor(labelColor),
      labelClassName,
    );
  }, [labelColor, labelClassName]);

  const inputClasses = useMemo(() => {
    return classNames(
      'mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none',
      getTailwindTextColor(inputColor),
      `${error ? 'border-red-400' : ''}`,
      inputClassName,
    );
  }, [inputColor, inputClassName, error]);

  return (
    <div className={colSpan} key={name}>
      <label
        htmlFor={name}
        data-testid={`${name.toLowerCase()}-label`}
        className={labelClasses}
      >
        {label}
        {required && <span title="required">*</span>}
      </label>
      <input
        type="text"
        id={name}
        placeholder={placeholder}
        data-testid={`${name}-input`}
        {...register(name)}
        className={inputClasses}
      />
      <ErrorField name={name} message={error?.message?.toString() ?? ''} />
    </div>
  );
};
