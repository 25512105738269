'use client';
import { ChangeEvent, useMemo, useRef, memo } from 'react';
import { FormField } from '../form-field';
import {
  dataURIWithFilenametoFile,
  fileToDataUrlWithName,
  getComponentClasses,
  getComponentColor,
  getComponentModifier,
  getComponentSize,
  memoPropsComponent,
  // memoPropsComponent,
} from '../utils';
import { getAcceptedFiles } from './get-accepted-files';
import { InputFileValue, UploadFileFieldProps } from './types';

function BaseUploadFileField({
  id,
  name,
  label,
  className,
  overwriteClass,
  color,
  size,
  disabled,
  bordered,
  ghost,
  multiple,
  accept,
  error,
  value,
  onChange,
  isDataUrl,
  ...props
}: UploadFileFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const inputFileClasses = getComponentClasses(
    { className, overwriteClass },
    'file-input',
    getComponentColor('file-input', color),
    getComponentSize('file-input', size),
    getComponentModifier('file-input', 'bordered', bordered),
    getComponentModifier('file-input', 'ghost', ghost),
  );

  const handleChanges = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.bubbles) return;

    const e: ChangeEvent<HTMLInputElement> = event;

    const files = event.target.files;

    if (isDataUrl && files && onChange) {
      const value = await transformFilesIntoDataUrl(files);

      const newEvent = {
        ...e,
        target: { ...e.target, value: value[0], name },
      };

      return onChange(newEvent);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const transformFilesIntoDataUrl = async (files: FileList) => {
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) promises.push(fileToDataUrlWithName(file));
    }

    const dataUrls = await Promise.all(promises);
    return dataUrls;
  };

  // function to dispatch a change event to the input file with a file list
  // this is used to reset the input file
  const dispatchChangeEvent = async (fileList: InputFileValue) => {
    if (!inputRef.current || !fileList) {
      return;
    }

    const dt = new DataTransfer();

    if (fileList instanceof FileList) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File | null = fileList.item(i);
        if (file) dt.items.add(file);
      }
    }

    if (fileList instanceof File) {
      dt.items.add(fileList);
    }

    if (fileList instanceof Array) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File | null = fileList[i];
        if (file) dt.items.add(file);
      }
    }

    if (typeof fileList === 'string' && isDataUrl) {
      const file = await dataURIWithFilenametoFile(fileList);
      dt.items.add(file);
    }

    if (typeof fileList === 'string' && !isDataUrl) {
      const file = new File([fileList], fileList.split('\\').pop() || 'file');
      dt.items.add(file);
    }

    const event = new Event('change', {
      bubbles: false,
      composed: true,
      cancelable: true,
    });
    const input = inputRef.current;
    input.files = dt.files;

    input.dispatchEvent(event);
  };

  useMemo(
    () => setTimeout(() => dispatchChangeEvent(value), 10),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, error],
  );

  return (
    <FormField name={name} label={label} error={error}>
      <input
        ref={inputRef}
        id={id}
        multiple={multiple}
        type="file"
        name={name}
        className={inputFileClasses}
        disabled={disabled}
        accept={getAcceptedFiles(accept)}
        onChange={handleChanges}
        {...props}
      />
    </FormField>
  );
}

export const UploadFileField = memo(BaseUploadFileField, memoPropsComponent);
