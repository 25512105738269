import { TextColorType, TextSizeType } from '../../types';

export const TextColorProp: {
  [key in TextColorType]: string;
} = {
  neutral: 'text-neutral',
  primary: 'text-primary',
  secondary: 'text-secondary',
  accent: 'text-accent',
  info: 'text-info',
  warning: 'text-warning',
  success: 'text-success',
  error: 'text-error',
  'base-100': 'text-base-100',
  'base-200': 'text-base-200',
  'base-300': 'text-base-300',
  'base-content': 'text-base-content',
  'base-100-content': 'text-base-100-content',
  'base-200-content': 'text-base-200-content',
  'base-300-content': 'text-base-300-content',
  'neutral-content': 'text-neutral-content',
  'primary-content': 'text-primary-content',
  'secondary-content': 'text-secondary-content',
  'accent-content': 'text-accent-content',
  'info-content': 'text-info-content',
  'warning-content': 'text-warning-content',
  'success-content': 'text-success-content',
  'error-content': 'text-error-content',
  base: 'text-base',
};

export const TextSizeProps: {
  [key in TextSizeType]: string;
} = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-md',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
  '6xl': 'text-6xl',
  '7xl': 'text-7xl',
  '8xl': 'text-8xl',
  '9xl': 'text-9xl',
};
