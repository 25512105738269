import { useTranslation } from 'next-i18next';
import * as yup from 'yup';
import { displayToast } from '@prickle/next-ui';
import { handleErrors } from '../utils/errors/handle-clientside-errors';
import { AssignmentsForm, AssignmentsFormControl } from '../types';
import { useForm } from '@prickle/form/formik';

export function useApplicationForm({
  slug,
  tag,
  role,
  endpoint,
}: {
  slug: string;
  tag: string;
  role: string;
  endpoint: string;
}) {
  const { t } = useTranslation('common');
  const { t: tForm } = useTranslation('form');

  const schema = yup.object().shape({
    name: yup.string().required(tForm('required-error', { label: t('name') })),
    lastName: yup
      .string()
      .required(tForm('required-error', { label: t('last-name') })),
    email: yup
      .string()
      .email()
      .required(tForm('required-error', { label: t('email') })),
    phoneNumber: yup
      .string()
      .required(tForm('required-error', { label: t('phone-number') })),
    letter: yup
      .string()
      .required(tForm('required-error', { label: t('cover-letter') })),
    resume: yup
      .string()
      .required(tForm('required-error', { label: t('resume') })),
  });

  const formProperties: AssignmentsFormControl = {
    name: {
      label: t('name'),
      required: true,
      name: 'name',
    },
    lastName: {
      label: t('last-name'),
      required: true,
      name: 'lastName',
    },
    email: {
      label: t('email'),
      required: true,
      name: 'email',
    },
    phoneNumber: {
      label: t('phone-number'),
      required: true,
      name: 'phoneNumber',
    },
    letter: {
      label: t('cover-letter'),
      required: true,
      name: 'letter',
    },
    resume: {
      label: t('resume'),
      required: true,
      name: 'resume',
    },
    submit: {
      label: t('submit'),
    },
  };

  const initialValues: AssignmentsForm = {
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    letter: null,
    resume: null,
    tag,
  };

  const submitJobVacancyApplicationForm = async ({
    email,
    letter,
    name,
    lastName,
    resume,
    phoneNumber,
  }: AssignmentsForm) => {
    const toast = displayToast({
      type: 'info',
      message: t('file-upload', {
        ns: 'common',
      }),
    });

    const formData = new FormData();
    formData.append('resume', resume as File);
    formData.append('name', name);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('letter', letter as File);
    formData.append('phone', phoneNumber);
    formData.append('role', role);
    formData.append('tag', tag);
    formData.append('slug', slug);

    const request = fetch(endpoint, {
      method: 'POST',
      body: formData,
    });

    await request
      .then(handleErrors)
      .then(() => {
        toast.close();

        displayToast({
          type: 'success',
          message: t('application-received', {
            ns: 'common',
          }),
        });
      })
      .catch((error) => {
        console.error(error);
        toast.close();
        displayToast({
          type: 'error',
          message: t('error-sending', {
            ns: 'common',
          }),
        });
      });
  };

  const {
    handleSubmit,
    handleChange,
    handleInputFileChange,
    values,
    errorMessages,
    isDisabled,
  } = useForm<AssignmentsForm>({
    initialValues,
    validationSchema: schema,
    onSubmit: submitJobVacancyApplicationForm,
  });

  return {
    formProperties,
    handleSubmit,
    values,
    errors: errorMessages,
    handleChange,
    handleInputFieldChange: handleInputFileChange,
    isDisabled,
  };
}
