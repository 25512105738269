import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';

interface Props {
  title: string;
  content: string;
}

export const MaintenanceView = ({ title, content }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen px-4 bg-base-300 text-base-content">
      <div className="max-w-md space-y-4 text-center">
        <HiOutlineWrenchScrewdriver size={64} className="mx-auto" />
        {/* <WrenchIcon className="w-12 h-12 mx-auto text-gray-500 dark:text-gray-400" /> */}
        <h1 className="text-3xl font-bold tracking-tigh">{title}</h1>
        <p className="text-gray-400">{content}</p>
      </div>
    </div>
  );
};
