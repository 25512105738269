import { ToastTemplate } from './toast.template';

import toast from 'react-simple-toasts';
import type { ToastOptions } from 'react-simple-toasts';

import { ToastProps } from './types/index';

interface IProps extends ToastProps {
  message: string;
  duration?: number;
  position?: ToastOptions['position'];
}

export const displayToast = ({
  message,
  duration = 5000,
  type,
  bottom,
  center,
  start,
  top,
  middle,
  end,
  className,
  overwriteClass,
  position,
}: IProps) => {
  return toast(
    <ToastTemplate
      bottom={bottom}
      center={center}
      start={start}
      top={top}
      type={type}
      middle={middle}
      end={end}
      className={className}
      overwriteClass={overwriteClass}
    >
      {message}
    </ToastTemplate>,
    { duration, maxVisibleToasts: 1, position },
  );
};
