import { ColorType } from '../../types';

export const BorderColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'border-neutral',
  primary: 'border-primary',
  secondary: 'border-secondary',
  accent: 'border-accent',
  info: 'border-info',
  warning: 'border-warning',
  success: 'border-success',
  error: 'border-error',
};
