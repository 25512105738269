import {
  Button,
  CheckboxInput,
  TextInput,
  TextareaFormField,
} from '@prickle/next-ui';
import { useTranslation } from 'next-i18next';

import { useContactForm } from '../../../hooks';

export function ContactForm() {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isDisabled,
    isSubmitting,
  } = useContactForm();
  const { t } = useTranslation('contact');

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        id="name"
        name="name"
        label={{
          text: t('contactname'),
          required: true,
          position: 'top',
        }}
        bordered
        placeholder={t('contactname')}
        onChange={handleChange}
        value={values.name}
        error={errors.name}
      />

      <TextInput
        id="email"
        name="email"
        label={{
          text: t('contactemail'),
          required: true,
          position: 'top',
        }}
        bordered
        placeholder={t('contactemail')}
        onChange={handleChange}
        value={values.email}
        error={errors.email}
      />

      <TextareaFormField
        id="message"
        name="message"
        label={{
          text: t('contactmessage'),
          required: true,
          position: 'top',
        }}
        bordered
        onChange={handleChange}
        value={values.message}
        rows={5}
        error={errors.message}
      />

      {/* TODO: Find the way to set the justify end to the checkbox */}
      <CheckboxInput
        id="newsletter"
        name="newsletter"
        label={{
          text: t('contactnewsletter'),
          position: 'right',
          containerClassName: 'justify-end',
        }}
        onChange={handleChange}
        checked={values.newsletter}
        error={errors.newsletter}
      />

      <Button color="primary" type="submit" disabled={isDisabled}>
        {isSubmitting ? t('contactsending') : t('contactsubmit')}
      </Button>
    </form>
  );
}
