import { format } from 'date-fns';
import Image from 'next/image';
import { Date, RichText } from 'prismic-reactjs';
import { BlogProps } from '../../types';
import Link from 'next/link';

export function Blog(articles: BlogProps): JSX.Element {
  return (
    <section className="container flex flex-col justify-center px-16 py-32 m-auto lg:px-0 font-body">
      <div className="flex flex-col items-center space-y-5 sm:mx-auto sm:space-y-4 ">
        <h2 className="text-center text-white heading-2" data-aos="fade-down">
          Blog
        </h2>
        <p
          className="mt-16 text-xl leading-7 text-white"
          data-aos="fade-down"
        ></p>
      </div>
      <div
        className="grid max-w-lg gap-12 mx-auto mt-12 lg:grid-cols-4 lg:max-w-none"
        data-aos="fade-up"
      >
        {articles.articles.map((article) => (
          <div
            className="flex flex-col overflow-hidden rounded-md shadow-xl"
            key={article.uid}
          >
            <div className="flex-shrink-0">
              <Image
                className="object-cover w-full h-48"
                src={article.data.featured_image.url}
                alt={RichText.asText(article.data.title)}
                width={100}
                height={100}
              ></Image>
            </div>
            <div className="flex flex-col justify-between flex-1 py-6">
              <div className="flex-1">
                <Link href={`/articles/${article.uid}`} className="block">
                  <h3 className="mt-2 text-xl font-semibold leading-7 text-white text">
                    {RichText.asText(article.data.title)}
                  </h3>
                  <p className="mt-3 text-sm leading-6 text-white truncate-3-lines">
                    {RichText.asText(article.data.excerpt)}
                  </p>
                </Link>
              </div>
              <div className="flex items-center mt-6">
                <div className="flex-shrink-0">
                  {/* <a href="#"> */}
                  <Image
                    className="object-cover w-10 h-10 rounded-full"
                    src={article.data.author.data.image.url}
                    alt=""
                    width={100}
                    height={100}
                  ></Image>
                  {/* </a> */}
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium leading-5 text-primary">
                    {/* <a href="#" className="hover:underline"> */}
                    {RichText.asText(article.data.author.data.name)}
                    {/* </a> */}
                  </p>
                  <div className="flex text-xs leading-5 text-white">
                    {format(
                      Date(article.first_publication_date ?? undefined),
                      'dd/MM/yyyy',
                    )}
                    <span className="mx-1">&middot;</span>
                    <span>6 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
