import { ColorType, StepsModifierType } from '../types';

export const StepColorProp: {
  [key in ColorType]: string;
} = {
  primary: 'step-primary',
  secondary: 'step-secondary',
  accent: 'step-accent',
  info: 'step-info',
  warning: 'step-warning',
  success: 'step-success',
  error: 'step-error',
  neutral: 'step-neutral',
};

export const StepsModifierProp: {
  [key in StepsModifierType]: string;
} = {
  horizontal: 'steps-horizontal',
  vertical: 'steps-vertical',
};
