'use client';

import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { NavigationElement } from '../navigation-element';
import Image from 'next/image';
import { NavbarProps } from './types';
import { classNames } from '../utils/tailwind';

export function Navbar({ logo, menu, align = 'right', children }: NavbarProps) {
  return (
    <Disclosure
      as="nav"
      className="sticky top-0 z-50 bg-neutral text-neutral-content"
    >
      {({ open }) => (
        <>
          <div
            className={`mr-2 max-w-8xl px-2 sm:px-6 lg:px-8 ${
              align === 'center' ? 'mx-auto' : ''
            }`}
          >
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md hover:bg-base-100/50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-base-100/60">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
                <div className="flex items-center flex-shrink-0">
                  <Image
                    className="block w-auto h-12 lg:hidden"
                    src={logo}
                    alt="Tuning Bytes"
                    height={0}
                    width={0}
                  />
                  <Image
                    className="hidden w-auto h-12 lg:block"
                    src={logo}
                    alt="Tuning Bytes"
                    height={0}
                    width={0}
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <ul className="flex space-x-4 menu menu-horizontal menu-lg">
                    {menu.map((item) => (
                      <NavigationElement item={item} key={item.name} />
                    ))}
                  </ul>
                </div>
              </div>
              {children}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden" role="menu">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {menu.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-base-100 text-base-content/100'
                      : 'hover:bg-base-300 hover:text-base-content/300',
                    'block rounded-md px-3 py-2 text-base font-medium',
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
