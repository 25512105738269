import React from 'react';

export function UnderConstruction(): JSX.Element {
  return (
    <section
      id="under-construction"
      className="h-full px-2 py-32 text-center bg-black md:px-0"
    >
      <div className="min-h-full px-6 py-16 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-3xl font-bold tracking-tight text-primaryLighter sm:text-3xl">
              503
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-xl tracking-tight text-white sm:text-xl">
                  Page under construction
                </h1>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}
