import { ColorType } from '../../types';

export const RingOffsetColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'ring-offset-neutral',
  primary: 'ring-offset-primary',
  secondary: 'ring-offset-secondary',
  accent: 'ring-offset-accent',
  info: 'ring-offset-info',
  warning: 'ring-offset-warning',
  success: 'ring-offset-success',
  error: 'ring-offset-error',
};
