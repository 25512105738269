import { ColorType } from '../../types';

export const CaretColorProp: {
  [key in ColorType]: string;
} = {
  neutral: 'caret-neutral',
  primary: 'caret-primary',
  secondary: 'caret-secondary',
  accent: 'caret-accent',
  info: 'caret-info',
  warning: 'caret-warning',
  success: 'caret-success',
  error: 'caret-error',
};
