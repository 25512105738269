import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { asText } from '@prismicio/client';
import Image from 'next/image';
import { Button } from '@prickle/next-ui';
import React, { Fragment } from 'react';
import { NavbarProps } from '../../types';
import { LanguageSelection } from '@prickle/next-ui';
import { IconMenu2 } from '@tabler/icons-react';
import Link from 'next/link';

export function Navbar({ logo, menu }: NavbarProps): JSX.Element {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <nav className="sticky top-0 z-10 w-full bg-black shadow-xl md:py-3 font-heading-alt">
      <div
        data-testid="navbar"
        className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
      >
        <div className="flex justify-between h-16">
          <div className="flex items-center mr-2 -ml-2 md:hidden ">
            <Button
              onClick={() => setNavbarOpen(!navbarOpen)}
              ghost
              className="inline-flex items-center justify-center p-2 text-white transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <IconMenu2 size={24} />
            </Button>
          </div>
          {logo && (
            <Link href="/" className="flex">
              <div className="flex items-center flex-shrink-0 ">
                <Image
                  className="w-24 h-8 cursor-pointer md:w-32"
                  src={logo}
                  alt="Logo"
                  width={100}
                  height={100}
                ></Image>
              </div>
            </Link>
          )}
          <div className="hidden md:ml-6 md:flex md:items-center">
            {menu.map((item, index) => {
              if (item.subMenu?.length) {
                return (
                  <div key={'item' + index}>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-xl font-bold text-white hover:text-primaryLighter">
                          {asText(item.name)}
                          <ChevronDownIcon
                            className="w-5 ml-2 -mr-1 h-7"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute left-0 z-10 w-56 mt-2 origin-top-right rounded-md bg-dark ">
                          <div className="py-1">
                            {item.subMenu.map((subItem, index) => (
                              <Menu.Item
                                key={asText(subItem.main_menu_name) + index}
                              >
                                <Link
                                  href={asText(subItem.link)}
                                  className="block px-4 py-2 text-xl text-white hover:text-primaryLighter"
                                >
                                  {asText(subItem.sub_menu_name)}
                                </Link>
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                );
              }
              return (
                <div key={'item' + index} className="px-5 py-2">
                  <Link
                    href={asText(item.link)}
                    className="text-xl font-bold tracking-wide text-white hover:text-primaryLighter"
                  >
                    {asText(item.name)}
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-white rounded-md shadow-sm">
                <LanguageSelection />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${navbarOpen ? 'block' : 'hidden '} md:hidden text-center `}
      >
        <div className="px-2 pt-2 pb-3">
          {menu.map((item, index) => (
            <Link
              key={'mobile_item' + index}
              href={asText(item.link)}
              className="block px-3 py-2 mt-1 text-base font-medium text-white transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-primaryLighter focus:outline-none focus:text-white focus:bg-primary-700"
            >
              {' '}
              {asText(item.name)}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}
