'use client';
import { Suspense, useEffect, useRef } from 'react';
import { ModalProps } from './types';
import { Button } from '../button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { getComponentClasses, getModalPosition } from '../utils';
import { Loading } from '../loading';

export function Modal({
  id,
  children,
  isOpen,
  onClose,
  closeIcon,
  clickOutsideToClose,
  className,
  overwriteClass,
  closeModalContainerClassName,
  containerClassName,
  position,
}: ModalProps) {
  const ref = useRef<HTMLDialogElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (!clickOutsideToClose) {
      ref.current?.addEventListener('cancel', (event) => {
        event.preventDefault();
      });
      return;
    }
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickOutsideToClose]);

  const dialogClasses = getComponentClasses(
    { overwriteClass, className: containerClassName },
    'modal',
    getModalPosition(position),
  );

  const modalBoxClasses = getComponentClasses(
    { overwriteClass, className },
    'modal-box',
  );

  const closeModalContainerClasses = getComponentClasses(
    { overwriteClass, className: closeModalContainerClassName },
    clickOutsideToClose ? 'modal-backdrop' : '',
  );

  useEffect(() => {
    if (isOpen) {
      ref?.current?.showModal();
    } else {
      ref?.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog id={id} className={dialogClasses} ref={ref} aria-hidden={!isOpen}>
      <div className={modalBoxClasses}>
        <div className={closeModalContainerClasses}>
          {closeIcon && (
            <Button
              size="sm"
              shape="circle"
              ghost
              className="absolute right-2 top-2"
              title="Close modal"
              onClick={onClose}
            >
              <XMarkIcon />
            </Button>
          )}
        </div>
        <Suspense
          fallback={<Loading size="md" color="accent" ariaText="Loading..." />}
        >
          <div>{children}</div>
        </Suspense>
      </div>
    </dialog>
  );
}
