import { TableModifierProp } from '../properties';
import { TableModifierType } from '../types';

export function getTableModifier(
  modifierKey: TableModifierType,
  isEnabled?: boolean,
) {
  if (!isEnabled) return '';

  return TableModifierProp[modifierKey];
}
