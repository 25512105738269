import { Alert } from '../alert/alert';
import { ToastProps } from './types/index';
import { getComponentClasses } from '../utils/daisyui/common-components/get-compoment-classes';
import { getToastPosition } from '../utils/daisyui/toast/get-toast-position';

export function ToastTemplate({
  children,
  className,
  overwriteClass,
  bottom,
  center,
  end,
  middle,
  start,
  top,
  type,
}: ToastProps) {
  const containerClasses = getComponentClasses(
    { className, overwriteClass },
    'toast',
    bottom ? getToastPosition('bottom') : '',
    center ? getToastPosition('center') : '',
    end ? getToastPosition('end') : '',
    middle ? getToastPosition('middle') : '',
    start ? getToastPosition('start') : '',
    top ? getToastPosition('top') : '',
  );

  return (
    <div className={containerClasses}>
      <Alert color={type}>{children}</Alert>
    </div>
  );
}
