'use client';
import { PhoneInputProps } from './types';

import {
  CountryIso2,
  defaultCountries,
  guessCountryByPartialPhoneNumber,
} from 'react-international-phone';
import {
  getComponentClasses,
  getComponentColor,
  getComponentModifier,
  getComponentSize,
  getCountryFlag,
  memoPropsComponent,
} from '../utils';
import { FormField } from '../form-field';
import { CountryCode } from '../../types';
import { ChangeEvent, memo, useEffect, useRef, useState } from 'react';
import { Button } from '../button';
import { cn } from '@prickle/ui';

function getCountryPrefix(country: CountryCode) {
  const countryPhone = defaultCountries.find((c) => c[1] === country);

  if (!countryPhone) {
    return '';
  }

  return `+${countryPhone[2]} `;
}

function getCountryFromPrefix(prefix: string) {
  const newCountry = guessCountryByPartialPhoneNumber({
    phone: prefix.replace('+', ''),
  });

  if (newCountry.fullDialCodeMatch && newCountry.country) {
    return newCountry.country.iso2;
  } else {
    return 'pirate';
  }
}

function BasePhoneInput({
  className,
  color,
  size,
  overwriteClass,
  name,
  defaultCountry = 'es',
  disabled,
  id,
  placeholder,
  label,
  value = '',
  onChange,
  error,
  ...props
}: PhoneInputProps) {
  const componentRef = useRef<HTMLElement>(null);
  const defaultCountryValue =
    value.toString().length > 0 ? getCountryFromPrefix(value) : defaultCountry;

  const [country, setCountry] = useState(defaultCountryValue);
  const [phoneNumber, setPhoneNumber] = useState(
    value || getCountryPrefix(defaultCountryValue as CountryCode),
  );
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const inputPhoneClasses = getComponentClasses(
    { className, overwriteClass },
    'input',
    getComponentColor('input', color),
    getComponentSize('input', size),
    getComponentModifier('input', 'bordered', true),
    'join-item',
    'w-full',
  );

  const onCountrySelect = (newCountry: CountryCode) => {
    const prevCountryPhonePrefix = getCountryPrefix(country as CountryCode);
    const countryPhone = getCountryPrefix(newCountry);
    setCountry(newCountry);

    if (!countryPhone) {
      // NOTE: This is necessary to avoid the error when the country is not found
      return;
    }

    if (phoneNumber.length === 0) return setPhoneNumber(countryPhone);

    prevCountryPhonePrefix
      ? setPhoneNumber(
          phoneNumber.replace(
            prevCountryPhonePrefix.trim(),
            countryPhone.trim(),
          ),
        )
      : setPhoneNumber(phoneNumber);
  };

  const changeCountryFlagUsingPrefix = (prefix: string) => {
    const newCountry = getCountryFromPrefix(prefix);

    setCountry(newCountry);
  };

  const onPhoneInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    changeCountryFlagUsingPrefix(value);

    setPhoneNumber(value);

    if (onChange) {
      const newEvent = {
        ...e,
        target: { ...e.target, value, name },
      };
      onChange(newEvent);
    }
  };

  const onStartSelectingCountry = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FormField name={name} label={label} error={error} ref={componentRef}>
      <div className="join">
        <Button ghost onClick={onStartSelectingCountry}>
          <span className="text-2xl">
            {getCountryFlag(country as CountryCode)}
          </span>
        </Button>
        <input
          id={id}
          ref={inputRef}
          name={name}
          type="text"
          className={inputPhoneClasses}
          placeholder={placeholder}
          disabled={disabled}
          value={phoneNumber}
          aria-label={label?.text?.toString() || 'phone'}
          onChange={onPhoneInputChange}
          {...props}
        />
      </div>
      <CountryDropList
        isOpen={isOpen}
        selectedCountry={country}
        onCountrySelect={onCountrySelect}
      />
    </FormField>
  );
}

const CountryDropList = ({
  isOpen,
  selectedCountry,
  onCountrySelect,
}: {
  isOpen: boolean;
  selectedCountry: string;
  onCountrySelect: (country: CountryCode) => void;
}) => {
  // ALLOW TO CHANGE THE COUNTRY cound using the input
  const countriesList = [...defaultCountries, ['pirate']];

  return (
    <ul
      aria-label="Countries"
      aria-expanded={isOpen}
      role="listbox"
      className={cn(
        'bg-base-100 h-0 overflow-y-auto w-auto border-separate mt-1',
        'transition-all ease-in-out delay-500',
        {
          'opacity-0': !isOpen,
          'h-48': isOpen,
        },
      )}
    >
      {countriesList.map((country) => (
        <CountryListItem
          key={`phone-${country[1]}`}
          country={country}
          selected={selectedCountry === country[1]}
          onCountrySelect={onCountrySelect}
        />
      ))}
    </ul>
  );
};

const CountryListItem = ({
  country,
  selected,
  onCountrySelect,
}: {
  country:
    | [string, CountryIso2, string, string, number]
    | string[]
    | [string, CountryIso2, string, string, number, string[]];
  selected?: boolean;
  onCountrySelect: (country: CountryCode) => void;
}) => {
  return (
    <li
      role="option"
      aria-selected={selected}
      className={cn(
        'pl-2 cursor-pointer hover:bg-neutral hover:text-neutral-content',
        {
          'bg-neutral text-neutral-content': selected,
        },
      )}
      onClick={() => onCountrySelect(country[1] as CountryCode)}
    >
      {getCountryFlag(country[1] as CountryCode)} {country[0]} (+{country[2]})
    </li>
  );
};

export const PhoneInput = memo(BasePhoneInput, memoPropsComponent);
