import NextLink from 'next/link';
import { LinkProps } from './types';
import {
  getComponentClasses,
  getComponentColor,
  getLinkModifier,
  getTextSize,
} from '../utils';

export function Link({
  href,
  className,
  overwriteClass,
  color,
  as = 'a',
  children,
  next: nextLinkProps,
  target,
  size,
  hover,
  id,
  rel,
  ...props
}: LinkProps) {
  const linkClasses = getComponentClasses(
    { className, overwriteClass },
    'link',
    getComponentColor('link', color),
    getTextSize(size),
    getLinkModifier('hover', hover),
  );

  if (as === 'next-link') {
    return (
      <NextLink
        locale={'en'}
        href={href}
        id={id}
        className={linkClasses}
        target={target}
        {...nextLinkProps}
        {...props}
      >
        {children}
      </NextLink>
    );
  }

  return (
    <a
      id={id}
      href={href}
      className={linkClasses}
      target={target}
      rel={rel}
      {...props}
    >
      {children}
    </a>
  );
}
