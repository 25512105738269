import { classNames } from '../../tailwind';

export type ComponentClasses = {
  className?: string;
  overwriteClass?: boolean;
};
export function getComponentClasses(
  { className = '', overwriteClass }: ComponentClasses,
  ...classes: string[]
): string {
  return overwriteClass // NOTE: if overwriteClass is passed, it will override all other classes
    ? className
    : classNames(...classes, className);
}
