import { LoadingShapeType, ResponsiveType } from '../types';

export const LoadingSizeProp: {
  [key in ResponsiveType]: string;
} = {
  xs: 'loading-xs',
  sm: 'loading-sm',
  md: 'loading-md',
  lg: 'loading-lg',
};

export const LoadingShaperProp: {
  [key in LoadingShapeType]: string;
} = {
  spinner: 'loading-spinner',
  dots: 'loading-dots',
  ring: 'loading-ring',
  ball: 'loading-ball',
  bars: 'loading-bars',
  infinity: 'loading-infinity',
};
