import { asText } from '@prismicio/client';
import Image from 'next/image';
import { FooterProps } from '../../types';
import Link from 'next/link';

export function Footer({ logo, menu }: FooterProps): JSX.Element {
  return (
    <footer data-testid="footer" className="bg-dark">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8 font-heading-alt">
        <nav className="flex flex-wrap justify-center -mx-5 -my-2">
          {menu.map((item, index) => (
            <div className="px-5 py-2" key={index}>
              <Link
                href={asText(item.link)}
                className="leading-6 text-white hover:link-primary" // SEE: hover:text-primaryLighter
              >
                {asText(item.name)}
              </Link>
            </div>
          ))}
        </nav>
        <div className="flex justify-center ">
          {logo && (
            <Image
              className="cursor-pointer"
              src={logo}
              alt="Logo"
              width={100}
              height={100}
            />
          )}
        </div>
        <p className="mt-8 text-xl leading-6 text-center text-white">
          &copy; {new Date().getFullYear()} Prickle, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
